import { CAR_FLOW_TYPE } from '~/data/car/carCardData';
import { carPageMock } from '~/data/resultsCarsDefaultData';
import { OptionType } from '../resultPageType';
import { ImageMultiMediaDB as ImageMultiMedia } from '~/data/lead/marketing';
import { CampaignNumber } from '~/services/LeadService/enums';
import { CarLabelData } from '../usedCar/usedCarData';
import { CampaignBannerType } from '~/components/ResultsPageBannerPromotion/ResultsPageBannerPromotion';
import { IWarrantyDetailsType } from '~/components/Warranty/Warranty';

export type CarType = {
  brandTitle: string;
  carPrice: {
    leasingPrice: string;
    fundingPrice: string;
    buyingPrice: string;
  };
  images: string[];
  modelTitle: string;
};
export type CarSafetyAndPollutionType = {
  finishLevelName: string;
  pollutionScore: number;
  safetyScore: string;
};

export interface IPromotionCardButton {
  id: number;
  label: string;
  buttonAction: string;
  link: string;
}

/* eslint-enable no-unused-vars */
export enum PromotionCardTemplate {
  TRADE_IN = 'tradeIn',
  CONTACT_US = 'contactUs',
  FUNDING = 'funding',
  GENERIC = 'generic',
}

export type BrandLeadData = {
  id?: number;
  campaignNumber: CampaignNumber;
  lineOfBusiness: string;
  note: string;
  subMedia: string;
  title: string;
};
export interface IPromotionCard {
  id?: number;
  title: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  showButton: boolean;
  regularLeadDetails?: BannerLeadDetailsType;
  cardSequence: number;
  titleImage?: ImageMultiMedia;
  button: IPromotionCardButton;
  brandsLeadDropdownOptions?: BrandLeadData[];
  template?: PromotionCardTemplate;
  preDesignedBanner?: boolean;
  desktopImage?: { data: ImageDB };
  mobileImage?: { data: ImageDB };
}
export interface IPromotionCardData {
  id: number;
  attributes: IPromotionCard;
}

export interface IPromotionCampaignCard {
  showInvoiceBanner?: boolean;
  showCarPageBanner?: boolean;
  iconbanner: any;
  icon: {
    url: string;
  };
  bannerTitle: string;
  bannerDescription: string;
  image?: string;
  tooltip: string;
  policyLink: string;
  startDate: Date;
  endDate: Date;
  carIds: {
    id: any;
    carId: number;
    page: 'newCar' | 'usedCar' | 'privateLeasing';
  }[];
  showTimer?: boolean;
}

export type BannerPromotionData = {
  regularLeadDetails: null | BannerLeadDetailsType;
  id: number;
  attributes: {
    isBannerAppears?: boolean;
    bannerTitle: string;
    bannerDescription: string;
    bannerOption: string;
    bannerButton: string;
    startDate: Date;
    endDate: Date;
    campaign: string;
    regularLeadDetails?: BannerLeadDetailsType;
    bannerIcon: { data: ImageDB };
    legalContent?: string;
  };
};

export enum BannerPromotionSkinColor {
  AquaSkin = 'AquaSkin',
  LightYellowSkin = 'LightYellowSkin',
}

export type IBannerPromotion = {
  isBannerAppears?: boolean;
  campaignBannerType?: CampaignBannerType;
  bannerTitle: string;
  isCampaignBanner?: boolean;
  tooltip?: string;
  policyLink?: string;
  bannerDescription: string;
  bannerOption?: string;
  bannerButton?: string;
  startDate?: Date;
  endDate?: Date;
  campaign?: string;
  image?: string;
  link?: string;
  icon?: string | { url: string };
  regularLeadDetails?: BannerLeadDetailsType | null;
  legalContent?: string;
  showCarPageBanner?: boolean;
  carPageBannerImage?: ImageMultiMedia;
  target?: string;
  brandsLeadDropdownOptions?: BrandLeadData[];
  showTimer?: boolean;
  carIds?: { page: string; carId: number }[];
  showInvoiceBanner?: boolean;
  skinColor?: BannerPromotionSkinColor;
  subtitle?: string;
  rank?: number;
  isDescriptionIncludeFreesbeLogo?: boolean;
};

export type ImageMultiMediaDB = { data: ImageDB[] };

export type BannerLeadDetailsType = {
  campaignNumber: string;
  id: number;
  lineOfBusiness: string;
  note: string;
  subMedia: string;
  title?: string | null;
};

export type NewAndLeaseCar = {
  id: number;
  attributes: {
    modelVehicle: string;
    leaseCarId?: string | number;
    newCarId?: string[] | CarType[] | number[];
    dmvManufacturerCode: string;
    dmvModelCode: string;
    dmvManufacturerName: string;
    dmvModelName: string;
    dmvFinishedLevelName: string;
    categories: string;
    year: number;
    dmvEngineCapacity: number;
    dmvIsAutomatic: number;
    dmvHorsepower: number;
    dmvFuel: string;
    dmvGear: string;
    dmvAirPollutionScore: number;
    dmvSafetyEquipmenScore: number;
    dmvIsDeadSpaceSystem: number;
    dmvIsAdaptiveCruiseControl: number;
    dmvIsEmergencyBrakingAgainstPedestrians: number;
    dmvIsAutomaticEmergencyBraking: number;
    dmvIsAutomaticEmergencyAgainstCyclistssystem: number;
    dmvIsPreventDeviationFromlaneSystem: number;
    dmvIsAutomaticBrakingInReverse: number;
    dmvIsISA: number;
    engineDescription: string;
    finishLevelDescription: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    type: string;
  };
};

export interface ILegalInfo {
  id: number;
  carDeal: CAR_FLOW_TYPE;
  legalText: string;
}

export interface IWarrantyInfo {
  warrantyByKm: number | null;
  warrantyByYears: number | null;
  batteryWarrantyByYears: number | null;
  batteryWarrantyByKm: number | null;
  isAutomatic: boolean;
  engineType: string;
  engineVolume: number;
}

export type CarCardType = {
  carHighlights?: {
    data: carDescriptionHighlights[];
  };
  warranties?: IWarrantyDetailsType[];
  batteryWarrantyByKm: string;
  warrantyByKm: string;
  warrantyByYears: string;
  pageViewsOneMonthAgo: number;
  id: number;
  familyCarId: string;
  familyCarZoneId: string;
  dmvManufacturerName: string;
  manufacturerDisplayName?: string;
  modelNameDisplayName: string;
  carCardBottomLabel: string;
  carCardBottomSubLabel: string;
  dmvModelName: string;
  categories: string;
  leaseCarMinPriceList?: number;
  newCarMinPrice: number;
  leaseMinPrice: number;
  leaseMinPriceList: number;
  promotion: FamilyCarPromotion;
  newCarLoanMinPrice: number;
  carDescriptionParagraph: string;
  newCarPrice: number[];
  licenseFeePrices: PriceBuyingType[];
  leaseCarPrice: number[];
  originalLeaseCarPrice: PriceType[];
  leaseCarPriceList: number[];
  newCarLoanPrice: number[];
  originalNewCarLoanPrice: PriceType[];
  freesbeCategories: OptionType[];
  carDescriptionHighlights: OptionType[];
  carProgrammaticDescription: string;
  images: string[];
  colors: string[];
  year: number;
  technicalSpecs: ITechnicalSpecsPDF;
  technicalSpecs_Link?: string;
  legalInfo: ILegalInfo[];
  publishedAt: string;
  carZoneMakeName?: string;
  carZoneMakeNameEn?: string;
  carZoneModelName?: string;
  carZoneModelNameEn?: string;
  carZoneSeriesName?: string;
  carZoneSeriesNameEn?: string;
  carZoneManufacturerName?: string;
  acquireType: string;
  subscriptionLabel?: SubscriptionLabelType;
  subscriptionPackagesLabel?: SubscriptionLabelType;
  subscriptionPackages?: SubscriptionPackagesType[] | null;
  subscriptionColors: CarCardSubscriptionColorType;
  rank?: number;
  leadType: string;
  isManualProgrammatic?: boolean;
  sitemapMakeName?: string;
  sitemapModelName?: string;
  carBodyType?: CarBodyType;
  carBullets?: CarBullet[];
  carLabels?: {
    data: CarLabelData[];
    length: number;
    [index: number]: CarLabelData;
  };
  share_type?: string;
  carBanner?: string;
  carVideo?: {
    videoUrl: string;
    uploadedVideo: string;
    image: string;
  };
  carManufacturerLogo?: string;
  interiorCarImages?: string[];
  ambianceCarImages?: string[];
  carPageHeadings: CarPageHeading;
  batteryWarrantyByYears?: string;
  tafnitFamilyId?: string;
  isTestDrive?: boolean;
  zeroKmQuantity?: number;
  attributes?: CarCardType;
  seo?: PageSEO;
};

export type FamilyCarPromoCardType = {
  id: number;
  manufacturerName: string;
  modelName: string;
  categories: string;
  bottomLabelTitle: string;
  images: string[];
  carLabels?: {
    id?: string;
    data: CarLabelData[];
  };
  leadDetails: BannerLeadDetailsType;
  promoTitle: string;
};

export type CarCardSubscriptionColorType = { [color: string]: string[] };

export interface SubscriptionLabelData {
  data: {
    attributes: {
      title: string;
      subTitle: string | null;
      icon: { data: null };
    };
    id: number;
  };
}
export interface SubscriptionPackagesData {
  attributes: SubscriptionPackagesType;
  id: number;
}
export interface SubscriptionPackagesType {
  bullets: { title: string; id: number }[];
  bulletsTitle: string;
  code: string;
  label: string | null;
  price: number;
  refMoreInfo: string;
  title: string;
  toolTip: string;
  bulletsGroups: BulletsGroupType[];
  invoiceValues: InvoiceValuesType[];
  dealPoints: { id: number; title: string }[];
}

export type BulletsGroupType = {
  groupTitle?: string;
  bullets: { id: number; title: string; toolTip?: string }[];
};

export type InvoiceValuesType = {
  title: string;
  description: string;
  price: number;
};

export type CarColorsAndImages = {
  id: number;
  modelVehicle: string;
  dmvManufacturerName: string | null;
  dmvModelName: string | null;
  dmvFinishedLevelName: string | null;
  categories: string;
  dmvModelCode: string;
  dmvManufacturerCode: string;
  year: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  displayFinishedLevelName: string | null;
  carZoneModelName: string;
  carZoneManufacturerName: string;
  carZoneFinishedLevelName: string;
  sitemap_exclude: boolean | null;
  isModelFleet: boolean;
  zeroKmQuantity: number;
  newCarId: any[];
  colors: Record<string, string>;
};

export type CarColorDetails = {
  carImg: string;
  carZoneFinishedLevelName: string;
};

export type SubscriptionLabelType = {
  title: string;
  subTitle: string | null;
  icon: { data: null };
};

export type FamilyCarPromotion = {
  title: string;
  topLabel: string;
  bottomLabel: string;
  isPublish: boolean;
};
export type FamilyCar = {
  familyCarZoneId?: string;
  id: number;
  attributes: {
    carSpecId?: any;
    newCarId: any;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
    dmvManufacturerCode: string;
    dmvModelCode: string;
    dmvManufacturerName: string;
    manufacturerDisplayName?: string;
    modelNameDisplayName: string;
    dmvModelName: string;
    categories: string;
    familyCarId: string;
    familyCarZoneId: string;
    newCarMinPrice: number;
    familyCarPromotion: FamilyCarPromotion;
    leaseMinPrice: number;
    carDescriptionParagraph: string;
    carProgrammaticDescription: string;
    newCarLoanMinPrice: number;
    leaseCarMinPriceList: number;
    newCarPrice: PriceBuyingType[];
    newCarLoanPrice: FundingPriceType[];
    leaseCarPrice: PriceType[];
    leaseCarPriceList: PriceType[];
    freesbeCategories: {
      data: freesbeCategoryType[];
    };
    carDescriptionHighlights: {
      data: carDescriptionHighlights[];
    };
    images: {
      data: ImageDB[];
    };
    technicalSpecs?: technicalSpecsPDF;
    technicalSpecs_Link?: string;
    colors: string;
    year: number;
    allColors: ColorRes[];
    carCardBottomLabel: {
      data: { id: number; attributes: ICarBottomLabel };
    };
    caCardTopLabel: {
      data: { id: number; attributes: ICarTopLabel };
    };
    legalInfo: ILegalInfo[];
    acquireType: string;
    subscriptionLabel?: SubscriptionLabelData;
    subscriptionPackagesLabel?: SubscriptionLabelData;
    subscriptionPackages?: { data: SubscriptionPackagesData[] };
    leadType: string;
    carBodyType: { data: CarBodyTypeData };
    carBullets: {
      data: {
        id: number;
        attributes: CarBullet;
      }[];
    };
    carLabels?: {
      data: CarLabelData[];
      length: number;
      [index: number]: CarLabelData;
    };
    carZoneModelNameEn: string;
    carZoneMakeNameEn: string;
    carBanner?: {
      data: ImageDB;
    };
    carManufacturerLogo?: {
      data: ImageDB;
    };
    carVideo?: {
      videoUrl: string;
      uploadedVideo: {
        data: ImageDB;
      };
      image: {
        data: ImageDB;
      };
    };
    interiorCarImages?: {
      data: ImageDB[];
    };
    ambianceCarImages?: {
      data: ImageDB[];
    };
    carPageHeadings: CarPageHeading;
    tafnitFamilyId?: string;
    isTestDrive?: boolean;
    pageViewsOneMonthAgo: number;
  };
};
export interface ICarBottomLabel {
  title: string;
  subLabelTitle: string;
}
export interface ICarTopLabel {
  text: string;
  subLabelTitle: string;
}

export const mapCarFamilyToCarCardType = (familyCar: FamilyCar) => {
  const { attributes, id } = familyCar;
  const { images } = attributes;
  const subscriptionColors: CarCardSubscriptionColorType = {};
  attributes.allColors?.forEach((color) => {
    const { title } = color;
    subscriptionColors[title] =
      color?.images?.data?.map((item) => item.attributes.url) || [];
  });

  const car: CarCardType = {
    ...attributes,
    promotion:
      attributes.familyCarPromotion ||
      carPageMock.attributes.familyCarPromotion,
    colors: attributes.allColors?.map((color) => color.title) ?? [],
    newCarPrice:
      attributes.newCarPrice?.map((carPrice) => carPrice.price) ?? [],
    licenseFeePrices: attributes.newCarPrice ?? [],
    newCarLoanPrice: attributes.newCarLoanPrice?.map(
      (carPrice) => carPrice.price,
    ),
    originalNewCarLoanPrice: attributes.newCarLoanPrice?.map((option) => {
      return {
        id: option.id,
        price: option.price,
        periodInMonths: option.loanMonth,
        advance: option.priceLoanAdvance,
        bullet: option.priceLoanBullet,
      };
    }),
    leaseCarPrice:
      attributes.leaseCarPrice?.map((carPrice) => carPrice.price) ?? [],
    originalLeaseCarPrice: attributes.leaseCarPrice ?? 0,
    id,
    freesbeCategories: attributes.freesbeCategories?.data.map(
      (freesebeCategory) => {
        const { name } = freesebeCategory.attributes;

        return { title: name };
      },
    ),
    carDescriptionHighlights: attributes.carDescriptionHighlights?.data.map(
      (carDescriptionHighlight) => {
        const { title, icon } = carDescriptionHighlight.attributes;

        return { title, icon: icon?.data?.attributes.url };
      },
    ),
    images: images?.data
      ? attributes.images.data?.map((image) => image.attributes.url)
      : [],
    carCardBottomLabel:
      attributes.carCardBottomLabel?.data?.attributes.title || '',
    carCardBottomSubLabel:
      attributes.carCardBottomLabel?.data?.attributes.subLabelTitle || '',
    technicalSpecs: {
      name: attributes?.technicalSpecs?.data?.attributes?.name,
      url: attributes?.technicalSpecs?.data?.attributes?.url,
    },
    technicalSpecs_Link: attributes.technicalSpecs_Link || '',
    leaseCarPriceList:
      attributes.leaseCarPriceList?.map(
        (leaseCarPrice) => leaseCarPrice.price,
      ) || [],
    leaseMinPriceList: attributes.leaseCarMinPriceList ?? 0,
    acquireType: attributes?.acquireType,
    subscriptionLabel: attributes?.subscriptionLabel?.data?.attributes,
    subscriptionPackagesLabel:
      attributes?.subscriptionPackagesLabel?.data?.attributes,
    subscriptionPackages: attributes?.subscriptionPackages?.data.map(
      (item: SubscriptionPackagesData) => {
        return item.attributes as SubscriptionPackagesType;
      },
    ),
    subscriptionColors,
    leadType: attributes.leadType,
    carBodyType: {
      title: attributes.carBodyType?.data?.attributes.title,
      displayTitleName:
        attributes.carBodyType?.data?.attributes.displayTitleName,
      icon: attributes.carBodyType?.data?.attributes?.icon?.data?.attributes
        .url,
    },
    carBullets:
      attributes.carBullets?.data?.map((bullet) => {
        return { ...(bullet?.attributes || null) };
      }) || [],
    carLabels: {
      data:
        [...(attributes.carLabels?.data || [])].sort((a, b) => a.id - b.id) ||
        [],
      length: attributes.carLabels?.length || 0,
    },
    carBanner: attributes.carBanner?.data?.attributes?.url || '',
    carVideo: {
      videoUrl: attributes.carVideo?.videoUrl || '',
      uploadedVideo:
        attributes.carVideo?.uploadedVideo?.data?.attributes?.url || '',
      image: attributes.carVideo?.image?.data?.attributes?.url || '',
    },
    carManufacturerLogo:
      attributes?.carManufacturerLogo?.data?.attributes?.url || '',
    interiorCarImages:
      attributes.interiorCarImages?.data?.map(
        (image) => image.attributes?.url,
      ) || [],
    ambianceCarImages:
      attributes.ambianceCarImages?.data?.map(
        (image) => image.attributes?.url,
      ) || [],
    carPageHeadings: attributes?.carPageHeadings,
    batteryWarrantyByKm: '',
    warrantyByKm: '',
    warrantyByYears: '',
    pageViewsOneMonthAgo: attributes?.pageViewsOneMonthAgo,
  };
  return car;
};

export const mapSearchCarsToCarCardType = (searchCar: ISearchCar) => {
  const promotionCarData = searchCar.familyCarPromotion
    ? searchCar.familyCarPromotion
    : carPageMock.attributes.familyCarPromotion;

  const car: CarCardType = {
    ...searchCar,
    promotion: promotionCarData,
    colors: searchCar.allColors?.map((color) => color.title) ?? [],
    newCarPrice: searchCar.newCarPrice?.map((carPrice) => carPrice.price) ?? [],
    licenseFeePrices: searchCar.newCarPrice ?? 0,
    newCarLoanPrice:
      searchCar.newCarLoanPrice?.map((carPrice) => carPrice.price) ?? [],
    originalNewCarLoanPrice:
      searchCar.newCarLoanPrice?.map((option) => {
        return {
          id: option.id,
          price: option.price,
          periodInMonths: option.loanMonth,
          advance: option.priceLoanAdvance,
          bullet: option.priceLoanBullet,
        };
      }) ?? [],
    leaseCarPrice:
      searchCar.leaseCarPrice?.map((carPrice) => carPrice.price) ?? [],
    originalLeaseCarPrice: searchCar.leaseCarPrice ?? [],
    freesbeCategories:
      searchCar.freesbeCategories?.map((freesebeCategory) => {
        const { name } = freesebeCategory;

        return { title: name };
      }) ?? [],
    carDescriptionHighlights: searchCar.carDescriptionHighlights?.map(
      (carDescriptionHighlight) => {
        const { title, icon } = carDescriptionHighlight;

        return { title, icon: icon?.url };
      },
    ),
    images: searchCar.images ? searchCar.images?.map((image) => image.url) : [],
    carCardBottomLabel: searchCar.carCardBottomLabel?.title || '',
    carCardBottomSubLabel: searchCar.carCardBottomLabel?.subLabelTitle || '',
    technicalSpecs: {
      name: searchCar?.technicalSpecs?.name || null,
      url: searchCar?.technicalSpecs?.url || null,
    },
    leaseCarPriceList:
      searchCar.leaseCarPriceList?.map(
        (leaseCarPrice) => leaseCarPrice.price,
      ) || [],
    leaseMinPriceList: searchCar.leaseCarMinPriceList ?? 0,
    acquireType: searchCar.acquireType,
    subscriptionColors: {},
    leadType: searchCar.leadType,
    carLabels: {
      data: Array.isArray(searchCar.carLabels)
        ? [...searchCar.carLabels].sort((a, b) => a.id - b.id)
        : [],
      length: searchCar.carLabels?.length || 0,
    },
    carPageHeadings: searchCar?.carPageHeadings || {},
    batteryWarrantyByKm: '',
    warrantyByKm: '',
    warrantyByYears: '',
    pageViewsOneMonthAgo: searchCar?.pageViewsOneMonthAgo,
  };
  return car;
};
export const getPurchasingDetails = (
  price: number,
  options: PriceType[],
): PriceType => {
  return options?.reduce((currentOption, newOption) => {
    return currentOption.price > newOption.price ? newOption : currentOption;
  }, options[0]);
};
export const getLicenseFeePriceDetails = (
  price: number,
  options: PriceBuyingType[] = [],
): PriceBuyingType | undefined => {
  return options.reduce((currentOption, newOption) => {
    return Math.abs(currentOption.price - price) >
      Math.abs(newOption.price - price)
      ? newOption
      : currentOption;
  }, options[0]);
};

interface PriceBuyingType {
  id: number;
  price: number;
  licenseFeePrice: number;
}
interface PriceType {
  id: number;
  price: number;
  advance?: number;
  periodInMonths?: number;
  bullet?: number;
  kmPerYear?: number;
  productCode?: number;
}

interface FundingPriceType {
  id: number;
  price: number;
  priceLoanAdvance?: number;
  loanMonth?: number;
  priceLoanBullet?: number;
}
interface ColorRes {
  id: number;
  title: string;
  dmvColor: number;
  colorHex: string;
  images: ImageMultiMediaDB;
}
export type ImageDB = {
  id: number;
  attributes: ImageContentDB;
};
export type ImageContentDB = {
  name: string;
  alternativeText: string;
  caption: string;
  width: null;
  height: null;
  formats: null;
  hash: string;
  ext: string;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
  provider: string;
  provider_metadata: null;
  createdAt: string;
  updatedAt: string;
};
export interface ITechnicalSpecsPDF {
  name?: string | null;
  url?: string | null;
}
export type technicalSpecsPDF = {
  data: {
    attributes: ITechnicalSpecsPDF;
  };
};

export type newCarId = {
  data: {
    id: number;
    attributes: {
      carModelCode: string;
      carId: string;
      carTitle: string;
      purchaseMethod: string;
      brand: string;
      brandDesc: string;
      quantity: number;
      expectedQuantity: number;
      price: string | number;
      engine: string;
      engineTitle: string;
      gear: string;
      gearTitle: string;
      finishLevelCode: string;
      subModelTitle: string;
      specialColorPrice: number;
      rishuiPrice: number;
      manufactureDate: number;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      modelVehicle: string;
      tradeMark: string;
      dmvManufacturerCode: string;
      dmvModelCode: string;
      dmvManufacturerName: string | null;
      dmvModelName: string | null;
      dmvFinishedLevelName: string | null;
      categories: string[] | null;
      smartPrice: SmartPrice;
      carColorsIds: carColorsIds;
    };
  }[];
};

export type SmartPrice = {
  id: number;
  webPrice: number;
  webPriceLoanAdvance: number;
  webPriceLoanBullet: number;
  webPriceLoanMinMonthlyPayment: number;
  webPriceLoanMonth: number;
  webPriceLoanPlanID: number;
  webPriceLoanPrincipal: number;
  webPriceLoanRate: number;
};

export type carColorsIds = {
  data: {
    id: number;
    attributes: {
      tos: string;
      quantity: number;
      expectedQuantity: number;
      price: number;
      isSpecial: number;
      metallicAddition: number;
      model: string;
      carId: string;
      createdAt: string;
      updatedAt: string;
      publishedAt: string;
      carColorsId: string;
    };
  }[];
};

export interface CarCardProps {
  car: CarCardType;
  isInWishlist: boolean;
  // eslint-disable-next-line no-unused-vars
  onWishlistButtonClicked: (carId: number) => void;
}
export interface FamilyCarPromoCardProps {
  familyCarPromo: FamilyCarPromoCardType;
  // isInWishlist: boolean;
  // // eslint-disable-next-line no-unused-vars
  // onWishlistButtonClicked: (carId: number) => void;
}

interface IFreesbeCategoryData {
  name: string;
  icon?: string;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}
interface freesbeCategoryType {
  id: number;
  attributes: {
    name: string;
    icon?: string;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}
export interface carDescriptionHighlights {
  id: number;
  attributes: {
    title: string;
    icon?: {
      data: ImageDB;
    };
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}

export interface ICarHightLightsData {
  title: string;
  icon?: { url: string };
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
}
export interface CarHightLights {
  title: string;
  icon?: string;
}

export interface CarBodyTypeData {
  id: number;
  attributes: {
    title: string;
    displayTitleName: string;
    icon?: {
      data: ImageDB;
    };
    isUsedCar?: boolean;
    isNewCar?: boolean;
    isLeaseCar?: boolean;
    createdAt: string;
    updatedAt: string;
    publishedAt: string;
  };
}
export interface CarBodyType {
  title: string;
  displayTitleName: string;
  icon?: string;
}

export interface CarBullet {
  carPageTitle: string;
  category: string;
  rank: number;
  resultsPageTitle: string;
}

export interface PageSEO {
  resultPageKeywords: string;
  resultPageMetaTitle: string;
  resultPageMetaDescription: string;
  carPageKeywords: string;
  carPageMetaTitle: string;
  carPageMetaDescription: string;
}
export interface ISearchCar {
  pageViewsOneMonthAgo: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  dmvManufacturerCode: string;
  dmvModelCode: string;
  dmvManufacturerName: string;
  manufacturerDisplayName?: string;
  modelNameDisplayName: string;
  dmvModelName: string;
  categories: string;
  familyCarId: string;
  familyCarZoneId: string;
  newCarMinPrice: number;
  familyCarPromotion: FamilyCarPromotion;
  leaseMinPrice: number;
  carDescriptionParagraph: string;
  carProgrammaticDescription: string;
  newCarLoanMinPrice: number;
  leaseCarMinPriceList: number;
  newCarPrice: PriceBuyingType[];
  newCarLoanPrice: FundingPriceType[];
  leaseCarPrice: PriceType[];
  leaseCarPriceList: PriceType[];
  freesbeCategories: IFreesbeCategoryData[];
  carDescriptionHighlights: ICarHightLightsData[];
  images: ImageContentDB[];
  technicalSpecs?: ITechnicalSpecsPDF;
  technicalSpecs_Link?: string;
  colors: string;
  year: number;
  allColors: ColorRes[];
  carCardBottomLabel: ICarBottomLabel;
  caCardTopLabel: ICarTopLabel;
  legalInfo: ILegalInfo[];
  acquireType: string;
  leadType: string;
  carZoneModelName?: string;
  carBullets: CarBullet[];
  carLabels?: CarLabelData[];
  carPageHeadings: CarPageHeading;
  isManualProgrammatic?: boolean;
  seo?: PageSEO;
}

export type SimilarFamilyCar = {
  carZoneModelNameEn: string;
  newCarMinPrice: number;
  newCarLoanMinPrice: number;
  carBanner: string;
  year: number;
  id: number;
  carZoneMakeNameEn: string;
  manufacturerDisplayName?: string;
  dmvModelName?: string;
  familyCarZoneId?: string;
  modelNameDisplayName?: string;
};

export type THeading = {
  title: string;
  subtitle: string;
};

export type CarPageHeading = {
  carInfo: THeading;
  interiorDesign: THeading;
};
export { CampaignBannerType };

export interface ILeaseCar {
  pageViewsOneMonthAgo: number;
  id: number;
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  dmvManufacturerCode: string;
  dmvModelCode: string;
  dmvManufacturerName: string;
  manufacturerDisplayName?: string;
  modelNameDisplayName: string;
  dmvModelName: string;
  categories: string;
  familyCarId: string;
  familyCarZoneId: string;
  newCarMinPrice: number;
  familyCarPromotion: FamilyCarPromotion;
  leaseMinPrice: number;
  carDescriptionParagraph: string;
  carProgrammaticDescription: string;
  newCarLoanMinPrice: number;
  leaseCarMinPriceList: number;
  newCarPrice: PriceBuyingType[];
  newCarLoanPrice: FundingPriceType[];
  leaseCarPrice: PriceType[];
  leaseCarPriceList: PriceType[];
  freesbeCategories: IFreesbeCategoryData[];
  carDescriptionHighlights: ICarHightLightsData[];
  images: ImageContentDB[];
  technicalSpecs?: ITechnicalSpecsPDF;
  technicalSpecs_Link?: string;
  colors: string;
  year: number;
  allColors: ColorRes[];
  carCardBottomLabel: ICarBottomLabel;
  caCardTopLabel: ICarTopLabel;
  legalInfo: ILegalInfo[];
  acquireType: string;
  leadType: string;
  carZoneModelName?: string;
  carBullets: CarBullet[];
  carLabels?: CarLabelData[];
  carPageHeadings: CarPageHeading;
  isManualProgrammatic?: boolean;
  seo?: PageSEO;
}
