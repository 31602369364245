export const RESOURCES = {
  REPORT_ACCIDENT_BUTTON: `למילוי טופס דיווח`,
  REPORT_ACCIDENT_RESOURCES: [
    {
      id: 1,
      title: `צלמו את זירות התאונה`,
      description: `מכל הזוויות, כולל הרכבים המעורבים, הנזקים ושלטי הדרך.`,
    },
    {
      id: 2,
      title: `החליפו פרטים עם כל המעורבים`,
      description: `שם מלא, מספר תעודת זהות, מספר רישיון נהיגה, פוליסת ביטוח, מספר טלפון ומספר רכב.`,
    },
    {
      id: 3,
      title: `צרו איתנו קשר בהקדם`,
      description: `שם מלא, מספר תעודת זהות, מספר רישיון נהיגה, פוליסת ביטוח, מספר טלפון ומספר רכב.`,
    },
  ],
  REPORT_ACCIDENT: 'עשיתם תאונה? דווחו לנו',
  REPORT_ACCIDENT_DESCRIPTION: `אז בתור התחלה, חשוב לעדכן אותנו וכדי שהתהליך יהיה פשוט וקל, הכנו בעבורכם מדריך פשוט להתנהלות במקרה של תביעת ביטוח רכב.`,
  INSURANCE_PAGE_SUB_TITLE: `מסלול הלוואה מותאם אישית, ליווי צמוד והכל בלי לגעת במסגרת האשראי שלך.`,
  TO_SEE_OUR_CARS: 'לצפייה ברכבים שלנו',
  MY_BUDGET: 'התקציב שלי',
  ABOUT_US: 'קצת עלינו',
  INSURANCE_ABOUT_US_FIRST_LINE: `כשאתם רוכשים רכב מקבוצת FRESBEE אתם בוודאי רוצים להיות בטוחים ומבוטחים כהלכה. לשם כך הוקמה קרסו סוכנות לביטוח (1989) בע"מ, מקבוצת freesbe. `,
  INSURANCE_ABOUT_US_SECOND_LINE: `לתפסיתנו, החוויה המושלמת והאחראית ביותר חייבת לקשור שירות, אחריות וביטוח תחת קורת גג אחת.עיקר ההתמחות שלנו הוא בביטוח רכב תוך בדיקה מול חברות הביטוח השונות והמובילות בשוק ולטובת מתן הצעת המחיר הטובה, האטרקטיבית והמותאמת ביותר לרכבך ובשילוב תנאי יבואן בלעדיים! את אמנת השירות שלנו תוכלו תמיד למצוא `,
  INSURANCE_ABOUT_US_LINK: 'כאן.',
  ABOUT_US_DESCRIPTION:
    'חברת המימון שלנו קיימת משנת 2008 ומאפשרת לך לרכוש את המכונית בנוחות ובגמישות מקסימלית. תכנון מסלול ומבנה ההלוואה מותאם לך באופן הכי משתלם ויצירתי ונעשה בליווי מלא וצמוד. המימון אינו משפיע על מסגרת האשראי שלך בבנק ובכרטיסי האשראי.',
  FINANCE_FOR_YOU: 'מימון לרכב שמותאם בדיוק לצרכים שלכם!',
  NEED_HELP_BANNER_TITLE: 'צריכים עזרה?',
  NEED_HELP_BANNER_DESCRIPTION: 'ספרו לנו מי אתם ואנחנו כבר ניצור אתכם קשר',
  ALL_CARS: 'לכל הרכבים',
  LEASING_CARS_PRIVATE: 'רכבי הליסינג הפרטי שלנו',
  LEASING_CARS_DISCOVER_VEHICLES: 'גלו את המגוון',
  EASY: 'בקלות!',
  GENERIC_ERROR: 'הפעולה לא הצליחה, אנא נסו שנית',
  GENERIC_ERROR2: `<p tabIndex={0}>אופס, משהו השתבש אצלנו בדרך. כדאי לנסות שוב מאוחר יותר. במידה ועדיין לא הצלחתם צרו איתנו קשר בטלפון  <a href="tel:*6020">6020*</a> </p>`,
  WARNING_TEXT_PAYMENT_LAYOUT:
    'אחד או יותר מהשדות שהזנת לא נכונים. לא התבצעה עסקת חיוב לאשראי שהזנת',
  WARNING_TEXT_PAYMENT_LAYOUT_CREATE_CUSTOMER:
    'הפרטים שלך התקבלו, מתנצלים מאוד אבל הייתה בעיה אצלנו במערכת. ניצור איתך קשר עוד היום כדי שתוכלו לשריין את המכונית הזו ',
  MAX: 'מקסימום',
  OR: 'או',
  KM: `ק״מ`,
  CHOOSE: 'בחרו',
  MAX_AND_MORE: ' ומעלה ',
  MIN: 'מינימום',
  ZERO_KM: '| 0 ק״מ',
  CONTACT_US_BUTTON: 'צרו איתנו קשר',
  ABOUT_CAR: 'על הרכב',
  ABOUT_BATTERY: 'על הסוללה',
  NAV_BAR_TITLE: 'צ׳יפופו',
  NAV_BAR_BUTTON: 'בוא נמצא רכב',
  BEFORE_DISCOUNT1: 'מחיר לפני',
  BEFORE_DISCOUNT2: ' הנחת 0 ק"מ ',
  ZERO_KM1: '0 ק"מ',
  DISCOVER_VEHICLES: 'גלו רכבים',
  FLEET_BRAND_TITLE: 'המותגים שלנו',
  FLEET_BRAND_ALL: 'לכל המותגים',
  HOME_PAGE_HERO_BUTTON: 'מחפשים רכב להשכרה',
  END_SALE: 'לסיום ההטבה',
  HERO_COMPONENT_TITLE: 'תשתחררו, זו רק מכונית',
  HERO_COMPONENT_SUBTITLE_1: 'היי, אנחנו ',
  HERO_COMPONENT_SUBTITLE_2: ' ואצלנו יש הכל:',
  HERO_COMPONENT_SUBTITLE_3:
    'מכוניות חדשות, יד שנייה, ליסינג, השכרה, מימון, ביטוח וחיוך\n אז, איזו מכונית מתאימה לך?',
  HERO_COMPONENT_SUBTITLE_3_MOBILE:
    'מכוניות חדשות, יד שנייה, ליסינג, השכרה, מימון, \nביטוח וחיוך אז, איזו מכונית מתאימה לך?',
  HERO_COMPONENT_SEARCH_YOU_CAR: 'מתחילים מכאן',
  HERO_COMPONENT_ALL_CARS: 'לכל המכוניות',
  HERO_COMPONENT_ALL_CARS_NEW: 'לכל המכוניות החדשות',
  HERO_COMPONENT_ALL_CARS_USED: 'לכל מכוניות היד השנייה',
  HOMEPAGE_MOBILE_FOOTER_ALL_CARS_NEW: 'לכל החדשות',
  HOMEPAGE_MOBILE_FOOTER_ALL_CARS_USED: 'לכל מכוניות היד השנייה',
  WARRANTY_TITLE: 'ראש שקט, באחריות',
  WARRANTY: 'אחריות על הרכב',
  WARRANTY_BATTERY: 'אחריות על הסוללה',
  WARRANTY_FIRST: '(המוקדם מביניהם).',
  AUTOMATIC_TEXT: 'אוטומטי',
  MANUAL_TEXT: 'ידני',
  ILS: 'ש״ח',
  PLAY: 'הפעל',
  PAUSE: 'עצור',
  CONTACT_US_BANNER_TITLE: 'צריכים עזרה? דברו איתנו',
  CONTACT_US_BANNER_DESCRIPTION:
    ' רוצים לדעת עוד? עקבו אחרי הטיפים שלנו באתר ואל תתביישו ליצור איתנו קשר',
  WELCOME_STEP_TITLE: 'כיף שהגעתם!',
  FINISH_LEAVE_DETAILS_BACK_TO_RESULTS: 'חזרו לעמוד התוצאות',
  WELCOME_STEP_TEXT:
    'אנחנו יודעים שרכב זו הוצאה לא פשוטה, תנו לנו לעזור לכם. בשתי שאלות קצרות נעזור לכם למצוא את האופציות הטובות ביותר.',
  WELCOME_STEP_TIP_TITLE: 'איך בוחרים?',
  WELCOME_STEP_TIP_TEXT:
    'עקבו אחרי ההמלצות שלנו שיהיו מסומנת כך ותוכלו ללמוד עוד קצת על דברים לא הכרתם',
  WELCOME_STEP_NOTE: 'חפשו בצהוב את  האופציות שאתם עוד לא מכירים ',
  WELCOME_STEP_BUTTON: 'שנתחיל?',
  FIRST_STEP_TITLE: 'מה הרכב הבא שלך?',
  NEW_CAR_TIP_TITLE: 'מה מיוחד ברכב חדש אצלנו?',
  NEW_CAR_TIP_TEXT:
    'רכב חדש מאפשר החזרה במקרה הצורך, התאמת הרכב לצרכים הייחודיים שלכם ותוכנית ביטוח אישית',
  RENTAL_CAR_TIP_TITLE: 'מה מיוחד ברכב זמני אצלנו?',
  RENTAL_CAR_TIP_TEXT:
    'רכב זמני אצלנו יכול להיות אחרת,  ב-freesbe נאפשר השכרה לטווח קצר אבל גם למספר חודשים אם אתם בדיוק בין עבודות',
  USED_CAR_TIP_TITLE: 'מה מיוחד ברכב מחודש אצלנו?',
  USED_CAR_TIP_TEXT:
    'רכב זמני אצלנו יכול להיות אחרת,  ב-freesbe נאפשר השכרה לטווח קצר אבל גם למספר חודשים אם אתם בדיוק בין עבודות',
  USED_CAR_IMAGE_ALT:
    '{carZoneManufacturerName} {carZoneModelName} {year} בצבע {carZoneColor} עם {kms} ק״מ, למכירה ב-freesbe. תמונה {imageIndex} מבין {totalImages}',
  USED_CAR_IMAGE_ALT_DEFAULT:
    '{carZoneManufacturerName} {carZoneModelName} {year} בצבע {carZoneColor} עם {kms} ק״מ, למכירה ב-freesbe. אייקון של רכב',
  CAR_IMAGE_ALT:
    '{dmvManufacturerName} {dmvModelName} למכירה ב-freesbe. תמונה {imageIndex} מבין {totalImages} תמונות',
  NEXT_STEP_BUTTON: 'להמשך',
  SHOW_CARS: 'לצפייה ברכבים',
  SKIP_BUTTON: 'דלג',
  BACK_BUTTON: 'חזרה',
  NEW_CAR: 'חדשה',
  NEW_CAR_TITLE: 'חדש',
  USED_CAR: 'יד שנייה',
  NEW_CAR_LABEL: 'רכב חדש',
  USED_CAR_LABEL: 'יד שנייה',
  NAV_PARAM_USED_CAR: 'used-car',
  START_DATE: 'תאריך התחלה',
  END_DATE: 'תאריך סיום',
  SELECT_DATES: 'בחר תאריכים',
  RENTAL_CAR: 'השכרה',
  RENTAL: 'השכרה',
  CHOOSE_YOUR_CAR_TITLE: 'מה באתם לחפש?',
  BUDGET_STEP_HEADER: 'כמה חשבת לשלם?',
  BUDGET_STEP_TIP_TITLE: 'מה יש בתקציב שלי?',
  PAYMENT_METHOD_OPTION: ['חודשי', 'חד-פעמי'],
  BUTTON_GROUP_TITLE: 'איך נוח לכם?',
  PAYMENT_EXPLANATION:
    'שתי שורות על מה זה אומר תשלום חד פעמי ואולי גם מה הטווח האופטימלי',
  TIME_PERIOD_STEP_HEADER: 'חשבתם לכמה זמן?',
  TIME_PERIOD_STEP_TIP_TITLE: 'השכרה לטווח ארוך',
  TIME_PERIOD_STEP_TIP_TEXT:
    'את התוצאות עבור הרכב הבא שלכם נציג באתר הייעודי - הכל כדי שתגיעו ליעד הבא כמו שצריך',
  RENTAL_CAR_RANGE_DATES: 'אפשר לחפש לפי תאריכים',
  LONG_TERM_CAR_RENTAL: 'ואפשר גם לבחור את הזמן המתאים',
  DATE_OPTIONS_LINE: 'ואולי בכלל תעדיפו אחרת',
  RENT_CAR_FOR_A_PERIOD: 'מכונית זמנית לתקופה של',
  RENT_CAR_FOR_A_PERIOD_WEEKEND: 'מכונית זמנית לסוף השבוע הקרוב',
  RENT_CAR_FOR_A_PERIOD_DAILY: 'מכונית זמנית לתקופה יומית',
  RENT_CAR_FOR_A_PERIOD_SIX_MONTHS: 'מכונית זמנית לתקופה של חצי שנה',
  WE_HAVE_MATCHING_VARIETY: 'אפשר לבחור מספר דברים ונציג את כל מה שמתאים',
  WHAT_IS_YOUR_KIND: 'על איזו חדשה חשבת?',
  GO_TO_SEARCH: 'תראו לי מכוניות',
  CONTACT_US_EMAIL: 'שלחו לנו מייל',
  CONTACT_US_PHONE: '077-8040834',
  CONTACT_US_PHONE_CALL_US: 'חייגו אלינו',
  CONTACT_US_WHATSAPP: 'Whatsapp לשליחת',
  CONTACT_US_BANNER_WHATSAPP: 'לשליחת WhatsApp',
  CONTACT_US_BY_WHATSAPP: 'WhatsApp',
  CONTACT_US_PAGE_WHATSAPP: 'שלחו לנו Whatsapp',
  CONTACT_US_PAGE_WHATSAPP_MOBILE: 'Whatsapp',
  CONTACT_US_WHATSAPP_POPUP: ' לשליחת Whatsapp',
  CONTACT_US_WHATSAPP_REVERSE: 'שלחו לנו WhatsApp',
  CONTACT_US_MODEL_TEXT: 'אנחנו זמינים פה תמיד לכל עזרה או בקשה',
  CONTACT_US_MODAL_DETAILS: 'להשארת פרטים',
  CONTACT_US_MODAL_CALENDAR: 'קבעו איתנו פגישה',
  CONTACT_US_ADDRESS_ARIA_LABEL: 'כתובת',
  CONTACT_US_EMAIL_ARIA_LABEL: 'אימייל',
  CONTACT_US_PHONE_ARIA_LABEL: 'טלפון נייד',
  POPUP_LOOKING_FOR_MATCHES_TITLE1: '...עוד שנייה מגיעים',
  POPUP_LOOKING_FOR_MATCHES_TITLE2: 'מחפשים התאמות',
  LOOKING_FOR_MATCHES_TEXT:
    'קבלנו את הפרטים שלך, כבר מעבירים אותך לכל הרכבים תוכלו להמשיך בחיפוש',
  BACK_CALL: 'תחזרו אליי',
  CAR_PURCHASE_METHODS_TITLE: 'אופציות רכישה',
  CAR_TOOL_TIP_BUTTON: 'ממה מורכב המחיר?',
  CAR_UPSALE_TEXT: 'נשארו 10 יחידות אחרונות במלאי',
  CAR_SUBMIT_BUTTON: 'להמשך',
  CAR_SUBMIT_BUTTON_B: 'למידע נוסף',
  CAR_SUBMIT_BUTTON_C: 'לפרטי הרכב',
  CAR_SUBMIT_BUTTON2: 'לפרטי הרכב',
  CAR_PURCHASE_TIME_PERIOD: 'לחודש',
  CAR_PURCHASE_OR: 'או',
  CAR_SPECIAL_PRICE_CONTEXT: 'מחיר מיוחד',
  CAR_ELECTRIC_TAX:
    'ביחס לחלק מהמלאי, שיעור מס הקניה החל על מחיר הרכב הוא שיעור מס הקניה שחל בגין שנת 2023, למרות שהרכב שוחרר מפיקוח המכס במהלך חודש ינואר 2024.',
  TOOLTIP_BUTTON_PURCHASE_PLANS: 'למדו על תכניות רכישה',
  TOOLTIP_LEARN_PURCHASE_OPTIONS: 'למדו על אפשרויות רכישה',
  TOOLTIP_CHOOSE_YOUR_PURCHASE_METHODS:
    'בחרו את הצורה בה אתם רוצים לרכוש, תוכלו להשוות בין ההצעות בדף הרכב',
  TOOLTIP_UNDERSTOOD_BUTTON: 'הבנתי',
  TOOLTIP_SELECTED_TYPE_DESCRIPTION_TITLE_1: ' מה זה ',
  TOOLTIP_SELECTED_TYPE_DESCRIPTION_TITLE_2: ' והאם זה טוב לי? ',
  FILTERS_BUTTON: 'כל המסננים',
  FILTERS_POPUP_MORE_FILTERS: 'מסננים נוספים',
  FILTERS_POPUP_SHOW_RESULT_BUTTON1: 'שמרו וצפו ב ',
  FILTERS_POPUP_SHOW_RESULT_BUTTON2: ' תוצאות',
  FILTERS_POPUP_CLEAN_BUTTON: 'נקה הכל',
  FILTERS_POPUP_CLEAR_BUTTON: 'נקו',
  FILTERS_CLEAR_FILTER: 'להסרת סינון',
  FILTERS_CLEAR_ALL_FILTER: 'נקו את כל הסינונים',
  FILTERS_POPUP_SAVE_BUTTON: 'שמרו',
  CAR_PAGE_SAVE_TO_FAVORITE: 'שמירה',
  CAR_PAGE_IMAGE_INFO: 'התמונה להמחשה בלבד. ייתכן שגוון הצבע יהיה שונה בפועל',
  CAR_PAGE_DEFAULT_TITLE: 'freesbe | עמוד רכב',
  CAR_PAGE_SEO_DESCRIPTION: 'בואו לבחור ממגוון דגמי',
  CAR_CREATE_YOUR_CAR: 'להמשך',
  CAR_CREATE_YOUR_CAR_AB: 'להתאמה ושריון',
  CAR_LEARN_MORE_BUYING: 'למדו עוד על תכניות רכישה',
  CAR_AVAILABLE_COLORS: 'צבעי הדגם',
  CAR_PURCHASING_DETAILS_PERIOD: 'x חודשים',
  CAR_PURCHASING_DETAILS_ADVANCE: 'מקדמה ₪x  ',
  CAR_PURCHASING_DETAILS_HANDS: 'יד x',
  CAR_PURCHASING_DETAILS_KM: 'x ק״מ',
  CAR_PURCHASING_DETAILS_PERIOD_LEASING: 'במסלול לתקופה של x חודשים',
  CAR_PURCHASING_DETAILS_ADVANCE_LEASING:
    'הכולל מקדמה של x ש"ח ותשלום אגרת רישוי',
  CAR_PURCHASING_DETAILS_BULLET: 'סוף תקופה ₪x',
  CAR_LEASE_TOOLTIP:
    'המחירים עבור רכב חדש בתשלום חודשי צמוד למדד המחירים לצרכן ותשלום מקדמה על סך {advance} ש"ח, לתקופה של {period} חודשים וכפופים למחירון יבואן, לא כולל שירותי ביטוח, טיפולים ואגרת רישוי.',
  TOOL_TIP_WARRANTY:
    'אחריות מלאה על כלל מכללי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.  אחריות תינתן כמפורט בחוברת האחריות.',
  FILTERS_CLEAN_ALL_BUTTON: 'נקו הכל',
  FILTERS_SORT_BY: 'מיין לפי :',
  FILTERS_RESULTS_DISPLAY1: 'מציגים',
  FILTERS_RESULTS_DISPLAY2: 'תוצאות',
  NO_RESULTS_FOUND_1: 'עדיין לא מצאנו את המכונית המתאימה',
  NO_RESULTS_FOUND_2: 'עם שינוי של אחד הפרמטרים זה יכול לעבוד!',
  FILTER_BUDGET_HEADER_TEXT: ' טווח מחירים',
  FILTER_BUDGET_PLACEHOLDER_TEXT: 'בחר תקציב',
  FILTER_BUDGET_TEXT: 'מחיר',
  APPROVAL_QUERY_TITLE: 'בואו נבדוק ביחד את המימון שלך',
  APPROVAL_QUERY_DESCRIPTION: 'הכל נמצא אונליין, חצי דקה וסיימנו',
  APPROVAL_QUERY_CONCLUSION:
    'האישור העקרוני מיועד לרכישת הרכב על ידך מתבסס על המידע שמסרת ותקף למי שהרכב נרכש עבורו וסך החזרי ההלוואות אינו עולה על 30% מהכנסותיו החודשיות. תוקף האישור העקרוני למשך 7 ימים ומיועד ללקוחות פרטיים. מתן ההלוואה בפועל כפוף לאימות הנתונים וקבלת המסמכים הנדרשים וכן לאישור הגוף המממן, אוטוקאש שרותי מימון בע"מ מקבוצת פריסבי, בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב ריבית פיגורים והליכי הוצאה לפועל.',
  APPROVAL_YOUR_AGE_CHECK: 'האם את/ה מעל גיל 24?',
  APPROVAL_YOUR_PAST_CHECK:
    'האם היו ב-3 השנים האחרונות אירועים של אי עמידה בתשלומים?',
  YEARS: 'שנים',
  APPROVAL_YOUR_CREDIT_CHECK:
    'קיים חשבון בנק פעיל על שמך בוותק של יותר משנתיים?',
  APPROVAL_GET_YOU_CODE: 'קבלו את הקוד שלכם',
  APPROVAL_ANSWER_YES: 'כן',
  EMPTY_DETAILS: '-',
  APPROVAL_ANSWER_NO: 'לא',
  APPROVAL_ANSWER_YES_WAS: 'כן היו',
  APPROVAL_ANSWER_NO_WAS: 'לא היו לי בעיות',
  APPROVAL_STATUS_TITLE: 'סטטוס תעסוקתי',
  APPROVAL_OPTION_EMPLOYEE: 'שכיר',
  APPROVAL_OPTION_SELF_EMPLOYEE: 'עצמאי',
  APPROVAL_OPTION_PENSIONER: 'פנסיונר',
  APPROVAL_OPTION_UNEMPLOYED: 'לא עובד',
  APPROVAL_SALARY_INCOM_TITLE: 'הכנסה חודשית נטו למשק בית',
  APPROVAL_CHECK_RESULT_TITLE: 'ממומן, ממומן, ממומן!',
  APPROVAL_CHECK_RESULT_DESCRIPTION:
    'נמצאתם מאושרים. רצינו להסביר שהאישור הוא אישור עקרוני ובסוף העסקה נחזור שוב ונאמת מחדש את הפרטים אשר הוזנו על ידכם.',
  APPROVAL_CHECK_RESULT_BOX_TITLE: 'רצינו להציע פה משהו מיוחד..',
  APPROVAL_CHECK_RESULT_BOX_DESCRIPTION:
    'נשמח להציע לכם שירות המשך ולקבל קוד אישי כדי לקבל מאיתנו הכניסו פרטים ליצירת קשר',
  APPROVAL_CHECK_RESULT_BUTTON_NEXT_TIME: 'אולי פעם אחרת',
  APPROVAL_CHECK_RESULT_BUTTON_CREATE_CODE: 'לקבלת קוד',
  APPROVAL_CONTACT_US_TITLE: 'רצינו להציע פה משהו מיוחד..',
  APPROVAL_CONTACT_US_DENIED_TITLE:
    'הבקשה בבדיקה, האם תרצה שנחזור אלייך להמשך פרטים?',
  APPROVAL_CONTACT_US_DESCRIPTION:
    'נשמח להציע לכם שירות המשך, כדי לקבל מאיתנו קוד לאישור העקרוני שיוכל לשמש אתכם לתהליך התאמת הרכב. הוסיפו עוד מספר פרטים וקבלו את הקוד ישירות אליכם',
  APPROVAL_CONTACT_US_DENIED_DESCRIPTION:
    'אנחנו צריכים עוד זמן כדי לאמת את הבקשה, האם תרצה להשאיר פרטים כדי שנציג שלנו יחזור אלייך בנושא?',
  APPROVAL_CONTACT_US_FIRST_NAME: 'שם פרטי',
  APPROVAL_CONTACT_US_LAST_NAME: 'שם משפחה',
  APPROVAL_CONTACT_US_EMAIL: 'אימייל (לא חובה)',
  PLACEORDER_CONTACT_US_EMAIL: 'לדוגמא: example@email.com',
  APPROVAL_CONTACT_US_PHONE: 'טלפון נייד',
  APPROVAL_CONTACT_US_I_APPROVE_MAILS: 'אני מאשר.ת דיוור',
  APPROVAL_CONTACT_US_CONTACT_ME: 'צרו איתי קשר',
  APPROVAL_CONTACT_US_MAYBE_NEXT_TIME: 'אולי פעם אחרת',
  USED_RESULT_PAGE_FIND_MORE: 'גלו עוד מכוניות',
  CHOOSE_YOUR_CAR: 'בחרו בדרך שלכם',
  CHOOSE_YOUR_CAR_SUBTITLE: 'חיפוש מהיר לפי יצרן ודגם או בחירה לפי קטגוריה',
  WHAT_IMPORTANT_TO_YOU: 'מה חשוב לכם ברכב הבא?',
  WHAT_IMPORTANT_TO_YOU_MOBILE: 'על איזו מכונית חשבת?',
  CATEGORIES_STEP_TITLE: 'מה חשוב לכם במכונית? ',
  OUR_TIP_FOR_CATEGORIES:
    'טיפ מאיתנו, בחרו את כל מה שמעניין אתכם  יש לנו מגוון בשביל כולם',

  SAFETY_TITLE: 'דירוג ומערכות בטיחות',
  SAFETY_BLOCK_TITLE: 'רמת אבזור בטיחותי',
  POLLUTION_BLOCK_TITLE: 'צריכת דלק וזיהום אוויר',
  SAFETY_SCALE_HIGH_LEVEL: 'רמת בטיחות גבוהה',
  SAFETY_SCALE_LOW_LEVEL: 'רמת בטיחות נמוכה',
  POLLUTION_SCALE_HIGH_LEVEL: 'זיהום מירבי',
  POLLUTION_SCALE_LOW_LEVEL: 'זיהום מזערי',

  TABLE_HEADER_MODEL_CODE: 'קוד דגם',
  TABLE_HEADER_MODEL_COLOR: 'צבעים זמינים',
  TABLE_HEADER_MODEL_DESCRIPTION: 'תיאור דגם',
  TABLE_HEADER_SAFETY_LEVEL: 'רמת אבזור בטיחותי',
  TABLE_HEADER_GAS_CONSUMPTION_REGULAR_AMERICAN: 'צריכת דלק בליטרים ל100 ק״מ*',
  TABLE_HEADER_GAS_CONSUMPTION_CHARGED:
    'צריכת דלק בנסיעה משולבת משוקללת ( ליטר / 100 קמ)',
  ZERO_CAR: 'רכב 0 ק״מ',
  TABLE_HEADER_POLLUTION_LEVEL: 'דרגת זיהום',
  WITH_NO_ADDITIONAL_PRICE: 'ללא תוספת מחיר',
  CAR_DETAILS_BUYING: 'מסלול קנייה',
  CAR_DETAILS_LEASING: 'מסלול ליסינג',
  CAR_DETAILS_LOAN: 'מסלול מימון',
  CAR_BRAND_MOCK: 'פולסווגן',
  CAR_MODEL_MOCK: 'Opel Insignia Sports',
  CAR_BID_ADDITIONAL: 'תוספת',
  CAR_BID_PRICE: 'מחיר',
  CAR_BID_PREPAYMENT: 'שלמו היום דמי רצינות',
  CAR_BID_DONT_WORRY: 'לא לדאוג! הסכום ירד מהמקדמה',
  CAR_BID_PRICE_MOCK: '₪ 800',
  CAR_BID_MY_OFFER: 'ההצעה שלי',
  CAR_BID_SUM: 'סה״כ לתשלום',
  CONFIGURATOR_SUMMERY: 'סיכום ההזמנה',
  CAR_DETAILS_FULL_OFFER: 'לההצעה המלאה',
  INCLUDE_IN_OFFER: 'כלול במחיר העסקה!',
  VIEW_FULL_DETAILS: 'הורידו את המפרט המלא',
  CALL_BACK_TO_YOU_WITH_OFFER: 'כל מה שצריך זה להקליק ונחזור אליך',
  CAR_FLOW_SUMMARY_TITLE: 'סיימתם ליצור את הרכב שלכם! איך נוכל להתקדם מפה?',
  CAR_FLOW_SUMMARY_DESCRIPTION: `עכשיו הזמן לשריין אותה בקלות\n עוד לא הגעת להחלטה? אפשר ליצור קשר ונתקדם משם`,
  CAR_FLOW_SUMMARY_APPOINTMENT: 'תיאום פגישה',
  CAR_FLOW_SUMMARY_LEAVE_DETAILS: 'השאירו פרטים',
  CAR_FLOW_SUMMARY_MORE_DETAILS: 'לפרטים נוספים',
  CAR_FLOW_SUMMARY_CALL_ME: 'תחזרו אליי',
  CAR_FLOW_SUMMARY_CALL_US: 'התקשרו אלינו',
  CAR_FLOW_SUMMARY_CONTACT_ME: 'ליצירת קשר',
  CAR_FLOW_IMPORTANT_TO_KNOW_DESCRIPTION:
    'המחיר כולל מעמ ואגרת רישוי התמונה להמחשה בלבד אספקת הרכב בכפוף לחתימה על הזמנה ותשלום מלא על הרכב מותנה בחתימה תוך 14 ימי עסקים אחריות לרכב - כמפורט בתנאי האחריות שבהזמנה. אחריות לאביזרים מקוריים - 3 שנים על ידי קרסו מוטורס. אחריות ליתר האביזרים - ידי ספק האביזר.',
  CAR_FLOW_SAVE_YOUR_CAR: 'לשריין את העסקה',
  CAR_FLOW_SAVE_YOUR_CAR_NEW: 'שריינו את הרכב',
  CAR_DETAILS_TALK_WITH_US: 'אפשר לשריין את העסקה בסוף התהליך',
  CAR_DETAILS_NEW_CAR_ON_WAY: 'ואנחנו נתחיל בטיפול',
  CAR_DETAILS_LEAVE_DETAILS: 'ליצירת קשר',
  CAR_DETIALS_SAVE_YOUR_CAR: 'שריינו את המכונית בסוף התהליך',
  SAVED_CARS: 'רכבים ששמרתי',
  SAVED: 'אהבתי',
  SAVED_CARS_ARE_HERE: 'כל הרכבים ששמרתם יופעו פה, עם  איזה תרצו להתקדם?',
  SAVE_CARS_INVITATION:
    'כאן אפשר לשמור את כל המכוניות שאהבת ולחזור אליהן ברגע המתאים\n כל מה שצריך זה לסמן ❤️',
  SEARCH_CARS: 'לחיפוש מכוניות',
  SEARCH_CAR: 'חפשו רכב',
  CAR_FLOW_FULL_OFFER: 'להצעה המלאה',
  CAR_FLOW_CONTAT_US: 'צרו איתנו קשר',
  TOOLTIP_BANNER: 'בכפוף לתקנון',
  TOOLTIP_BANNER1: 'בכפוף ל',
  TOOLTIP_BANNER2: 'תקנון',
  TIME_TO_END: 'המבצע מסתיים בעוד',
  TIME_TO_END_MOBILE: 'לסיום המבצע',
  CAR_FLOW_ON_US: 'עלינו',
  CAR_FLOW_INCLUDE_IN_PRICE: 'כלול במחיר',
  INCLUDE_IN_DEAL: 'כלול בעסקה',
  CAR_FLOW_RISHUI_PRICE: 'אגרת רישוי',
  CAR_CARD_HOW_BUILD_PRICE: 'ממה מורכב המחיר?',
  NAV_TITLE_BUY_SELL: 'קנייה ומכירה',
  NAV_TITLE_SOLUTIONS: 'פתרונות freesbe',
  NAV_TITLE_SERVICE: 'שירות',
  NAV_TITLE_POLICY: 'מידע ומדיניות',
  NAV_TITLE_CONTACT_US: 'דברו איתנו',
  NAV_TITLE_NEW_CAR: 'חדש',
  NAV_ITEM_ABOUT: 'אודות',
  NAV_ITEM_ACCESSIBILITY: 'הצהרת נגישות',
  NAV_ITEM_TERMS_OF_USE: 'תנאי השימוש',
  NAV_ITEM_TERMS_AND_CONDITIONS: 'תקנון',
  NAV_ITEM_PRIVACY: 'מדיניות פרטיות',
  NAV_ITEM_REFUNDS: 'מדיניות החזרות',
  NAV_ITEM_NEW_CAR: 'חדש',
  NAV_PARAM_NEW_CAR: 'new',
  NAV_ITEM_RENEW_CAR: 'יד שנייה',
  NAV_ITEM_FUNDING: 'מימון',
  NAV_ITEM_FUNDING_VALUE: 'funding',
  NAV_ITEM_INSURANCE: 'ביטוח',
  NAV_ITEM_EQUAL_SALARY_REPORT: 'דו״ח שכר שווה',
  NAV_ITEM_EQUAL_SALARY_REPORT_VALUE: 'equal-salary-report',
  NAV_ITEM_INSURANCE_VALUE: 'insurance',
  NAV_ITEM_TEMPORARY_CAR: 'זמנית',
  NAV_ITEM_DRIVING_LEASING: 'הליסינג שלי',
  NAV_ITEM_LEASING_CAR: 'ליסינג',
  NAV_ITEM_PRIVATE_LEASING_CAR: 'ליסינג פרטי',
  NAV_PARAM_PRIVATE_LEASING_CAR: 'private-leasing',
  NAV_ITEM_TREATMENT: 'זימון תור לטיפול',
  NAV_ITEM_CONSTRUCTION_EQUIPMENT_CASE: 'ציוד מכני הנדסי - Case',
  NAV_ITEM_CONSTRUCTION_EQUIPMENT_SANY: 'ציוד מכני הנדסי - Sany',
  NAV_ITEM_RENT_CAR: 'השכרה',
  NAV_ITEM_SOLUTIONS: 'פתרונות freesbe',
  NAV_ITEM_BUSINESS_LEASING: 'ליסינג עסקי',
  NAV_ITEM_OUR_BLOG: 'הבלוג שלנו',
  NAV_ITEM_CONTACT_US: 'צרו קשר',
  NAV_ITEM_CONTACT_US_PAGE: 'צרו איתנו קשר',
  NAV_ITEM_LOAN: 'מימון',
  NAV_ITEM_LOAN_CALCULATE: 'מחשבון מימון',
  NAV_ITEM_CHARGING_SOLUTIONS: 'פתרונות טעינה',
  NAV_ITEM_COMMUNITY: 'freesbe בקהילה',
  NAV_ITEM_SELL_CAR: 'רוצה למכור רכב',
  NAV_ITEM_CHARGING_SOLUTIONS_VALUE: 'charging-solutions',
  NAV_ITEM_BUSINESS_LEASING_VALUE: 'business-leasing',
  NAV_ITEM_CONTACT_LEAVE_APPLICATION: 'השארת פנייה',
  NAV_ITEM_CONTACT_OUR_BRANCHES: 'הסניפים שלנו',
  NAV_ITEM_CONTACT_CAREERS: 'קריירה',
  SEARCH_FLOW_BUDGET_TIP_PART1: 'מצאנו ',
  SEARCH_FLOW_BUDGET_TIP_PART2: ' מכוניות מתאימות',
  SAFETY_BULLET_TRUE: 'מערכת מותקנת בדגם הרכב',
  SAFETY_BULLET_FALSE: 'מערכת שאינה מותקנת בדגם הרכב',
  SAFETY_BULLET_COULD_BE_TRUE: 'מערכת אופציונלית להתקנה',
  SAFETY_BULLET_TITLE: 'פירוט מערכות הבטיחות המותקנות ברכב',
  SAFETY_BULLET_DICTIONARY_TITLE: '  מקרא :',
  SAFETY_BULLET_RULE_dmvIsDeadSpaceSystem: 'מערכת זיהוי כלי רכב ב"שטח מת"',
  SAFETY_BULLET_RULE_dmvIsAdaptiveCruiseControl: 'בקרת שיוט אדפטיבית',
  SAFETY_BULLET_RULE_dmvIsEmergencyBrakingAgainstPedestrians:
    'מערכת לבלימת חירום מפני הולכי רגל ורוכבי אופניים',
  SAFETY_BULLET_RULE_dmvIsAutomaticEmergencyBraking:
    'מערכת בלימה אוטומטית בעת חירום',
  SAFETY_BULLET_RULE_dmvIsAutomaticEmergencyAgainstCyclistssystem:
    'מערכת לבלימת חירום מפני הולכי רגל ורוכבי אופניים',
  SAFETY_BULLET_RULE_dmvIsPreventDeviationFromlaneSystem:
    'מערכת אקטיבית למניעת סטייה מנתיב',
  SAFETY_BULLET_RULE_dmvIsAutomaticBrakingInReverse:
    'בלימה אוטומטית בנסיעה לאחור',
  SAFETY_BULLET_RULE_dmvIsISA: 'בקרת מהירות חכמה ( ISA)',
  SKIP_TO_CONTENT: 'מעבר לתוכן',
  GENERIC_PAGE_TABLE_CAPTION: 'הסדרי נגישות לסניף',
  ACCESSORIES_INCLUDES: 'אביזרים הכלולים בעסקה שלי',
  ACCESSORIES_CARD_TITLE_LEASING: 'אביזרים נלווים הכלולים בעסקה שלי',
  ACCESSORIES_CARD: 'תוספות יצרן הכלולות במכונית שלי',
  CAR_DETAILS_TITLE: 'פרטי הרכב',
  CAR_DETAILS_CONF: 'פירוט פרטי תשלום',
  CAR_DETAILS_CONF_LEASING: 'להצגת פירוט מלא',
  CAR_DETAILS_CONF_LEASING_CLOSE: 'להסתרת פירוט מלא',
  BUYING_PRICE: 'מחיר קניה',
  FUNDING_PRICE: 'מחיר מימון',
  SEPERATE_LINE: ' | ',
  ACCESSORIES_AND_ADDITIONS: 'אביזרים ותוספות',
  ADDITIONS: 'תוספות',
  FULL_INFORMATION_BUTTON: 'למידע המלא',
  CAR_FLOW_TIME_FRAME_DESCRIPTION:
    'מחיר הליסינג שלכם יקבע בעזרת תקופת הליסינג וכמות הק״מ. בשלב הזה תוכלו לבחור את התקופה',
  DISTANCE_PACKAGE_TIP_TITLE: 'איך מחשבים בכלל ק״מ?',
  DISTANCE_PACKAGE_TIP_DESCRIPTION:
    'הנהגים הממוצעים המבלים בין 3-5 שעות על הכביש נוהגים בממוצע 20,000 ק״מ בשנה. מחיר הליסינג שלכם יקבע לפי מסגרת הק״מ בשילוב עם תקופת ליסינג.',
  CAR_FLOW_DESIGN_YOUR_CAR_LABEL: 'התאמת המכונית',
  CAR_FLOW_DESIGN_YOUR_CAR_TITLE: 'הגיע הזמן להתאים את המכונית שלך',
  COMPARE_FINISH_LEVELS: 'להשוואה מלאה בין רמות הגימור',
  COMPARE_LEASING_PLANS: 'להשוואה בין מסלולים » ',
  COMPARE_LEASING_CHOOSE_PLAN_TITLE: 'בחרו במסלול שהכי מתאים לכם ',
  SAME_CHARACTERISTICS: 'מאפיינים זהים ל',
  SEE_FINISH_LEVELS: 'לצפייה בעיקרי רמת הגימור',
  CHOOSE_FINISH_LEVEL: 'בחרו רמת גימור שהכי מתאימה לכם',
  CAR_FLOW_ACCESSORIES_LABEL: 'אביזרים',
  CAR_FLOW_ACCESSORIES_TITLE: 'הגיע הזמן לשדרג באביזרים',
  CAR_FLOW_WARRANTY_LABEL: 'אחריות',
  CAR_FLOW_WARRANTY_TITLE: 'דואגים לך, באחריות',
  CAR_FLOW_SERVICES_LABEL: 'שירותים מקצה לקצה',
  CAR_FLOW_SERVICES_TITLE: 'כל השירותים כאן. ועכשיו',
  CAR_FLOW_TIME_FRAME_LABEL: 'מסגרת זמנים',
  CAR_FLOW_TIME_FRAME_TITLE: 'מהי תקופת הליסינג המתאימה לך?',
  CAR_FLOW_LEASE_TIME_FRAME: 'תקופת ליסינג',
  CAR_FLOW_DISTANCE_PACKAGES_LABEL: 'חבילת ק״מ',
  CAR_FLOW_DISTANCE_PACKAGES_TITLE: 'כמה בערך נוסעים בשנה?',
  CAR_FLOW_PLAN_AND_MANAGEMENT_LABEL: 'מסלול וניהול',
  CAR_FLOW_PLAN_AND_MANAGEMENT_TITLE: 'שירות וניהול',
  CAR_FLOW_SUMMARY_STEP_LABEL: 'סיכום ופרטים',
  CAR_FLOW_SUMMARY_STEP_TITLE: 'סיימת להתאים את המכונית שלך!',
  CAR_RECOMMENDATION_TEXT: 'מכוניות נוספות שחשבנו שתאהבו',
  SAFETY_TOXIC_LEVEL_TITLE: 'רמת אבזור בטיחות',
  POLLUTION_TOXIC_LEVEL_TITLE: 'דרגת זיהום',
  PAYMENT_FLOW_HEADER: 'להבטיח את העסקה שלך',
  PAYMENT_FLOW_HEADER_NEW: 'צעד אחרון - שריינו את הרכב שלכם!',
  PAYMENT_FLOW_SUB_HEADER1: ' בתשלום מקדמה על סך ',
  PAYMENT_FLOW_SUB_HEADER2:
    'נשמור עבורך את תנאי העסקה ופרטי המכונית שהתאמת אישית. במידה ולא נשלים את העסקה המקדמה תוחזר.',
  PAYMENT_FLOW_SUB_HEADER2_PART2_1: 'במידה ולא נשלים את העסקה-',
  PAYMENT_FLOW_SUB_HEADER2_PART2_2: 'המקדמה תוחזר.',
  PAYMENT_FLOW_SUB_HEADER2_PART1:
    'נשמור עבורך את תנאי העסקה ופרטי המכונית שהתאמת אישית.',
  PAYMENT_FLOW_ACCORDION_USER_DETAILS_TITLE: 'הפרטים שלי',
  PAYMENT_FLOW_USER_DETAILS_INPUT_NOTE: 'פרטי הפנייה ',
  PAYMENT_FLOW_USER_DETAILS_INPUT_NOTE_PLACEHOLDER: 'ספרו לנו איך אפשר לעזור',
  PAYMENT_FLOW_USER_DETAILS_INPUT_LAST_NAME: 'שם משפחה',
  PAYMENT_FLOW_USER_DETAILS_INPUT_FIRST_NAME: 'שם פרטי',
  PAYMENT_FLOW_USER_DETAILS_INPUT_EMAIL: 'אימייל',
  PAYMENT_FLOW_USER_DETAILS_INPUT_EMAIL_NOT_REQ: 'אימייל (לא חובה)',
  PAYMENT_FLOW_USER_DETAILS_INPUT_CITY: 'עיר',
  PAYMENT_FLOW_USER_DETAILS_INPUT_ADDRESS: 'כתובת מגורים',
  PAYMENT_FLOW_USER_DETAILS_INPUT_ADDRESS_ERROR:
    'אנא הזן כתובת מלאה (רחוב מספר ועיר)',
  PAYMENT_FLOW_USER_DETAILS_INPUT_ADDRESS_ERROR2: 'בחר כתובת מהרשימה הנפתחת',
  PAYMENT_FLOW_USER_DETAILS_INPUT_ADDRESS_PLACEHOLDER: 'רחוב מספר ועיר',
  PAYMENT_FLOW_USER_DETAILS_INPUT_PHONE: 'טלפון נייד',
  PAYMENT_FLOW_USER_DETAILS_ID_NUMBER: 'תעודת זהות',
  PAYMENT_FLOW_USER_DETAILS_LICENSE_NUMBER: 'מספר רישוי  ',
  PAYMENT_FLOW_USER_DETAILS_BRANCH: 'סניף',
  PAYMENT_FLOW_USER_DETAILS_CHECKBOX_READ: ' אני מאשר.ת כי קראתי את כל ',
  PAYMENT_FLOW_USER_DETAILS_CHECKBOX_EMAIL:
    'אני מאשר.ת קבלת דיוור ותוכן שיווקי ופרסומי מהחברות השונות בקבוצת freesbe כמפורט במדיניות הפרטיות באמצעות סמס, וואטסאפ, דואר אלקטרוני, וחיוג אוטומטי.',
  FORM_CHECKBOX_APPROVE_PHONE_CALLS:
    'אני מאשר.ת שחברת freesbe תפנה אליי שיווקית באמצעות הטלפון.',
  PAYMENT_FLOW_USER_DETAILS_BTN: 'אשרו את הפרטים שלי',
  PAYMENT_FLOW_USER_DETAILS_BTN_DISABLED:
    'שליחת טופס , יש שגיאות בטופס או שחסרים פרטים בטופס , יש לחזור ולתקן',
  PAYMENT_FLOW_ACCORDION_PAYMENT_DETAILS_TITLE: 'פרטי אשראי ותשלום',
  PAYMENT_FLOW_UPDATE_DETAILS: 'ערכו מחדש',
  LET_MEET_WHILE_MOVEMENT: 'בואו להכיר, תוך כדי תנועה',
  PLANS_SUBTITLE_CARD: 'כל מה שצריך להכיר',
  FIT_YOU_PERFECTLY: 'חדשה מהניילונים שמתאימה לך בול',
  GET_YOUR_CODE_TITLE: 'מדהים, עכשיו יש לנו את הכל',
  GET_YOUR_CODE_DESCRIPTION:
    'מוכנים לזה? הנה הקוד! שלחנו לכם את הקוד גם לנייד כדי שלא תאבדו אותו בדרך. כמו שכבר סיפרנו, אחוז המימון לאחר האישור העקרוני הוא מהגבוהים.',
  GET_YOUR_CARD_GOOD_TO_KNOW: 'כדאי לדעת:',
  GET_YOUR_CARD_GOOD_TO_KNOW_DESCRIPTION:
    'אישור העקרוני יהיה תקף ל21 ימים ולאחר מכן יהיה צריך לבצע את התהליך מחדש',
  GET_YOU_CODE_BUTTON_LABEL: 'המשיכו למכונית החלומות',
  LOADER_TITLE: 'שנייה אנחנו בודקים',
  LOADER_DESCRIPTION:
    'רצינו להמשיך ולספר שאנחנו ממש טובים במימון, אבל עוד רגע התוצאה כאן',
  LOAN_APPROVAL: 'אישור עקרוני למימון',
  MONTHS: 'חודשים',
  CLICK_HERE_TO_CONTACT: 'להשארת פרטים',
  CLICK_HERE_TO_CONTACT_US: 'ליצירת קשר',
  CONTACT_TO_MEETING: 'תיאום פגישה',
  NEXT_STEP_BUTTON_CAR_FLOW: 'לשאלה הבאה',

  CONTACT_US_PAGE_TITLE: 'אנחנו ב-freesbe תמיד שמחים לשמוע ממך ',
  CONTACT_US_REFERRAL_REASON_PLACEHOLDER: 'בחרו את סיבת הפנייה שלכם',
  CONTACT_US_PAGE_TITLE_MOBILE: ' ב-freesbe תמיד שמחים לשמוע ממך ',
  CONTACT_US_PAGE_OPTIONS_TITLE: 'זמינים גם באמצעים הבאים',
  CONTACT_US_SUCCESS_PAGE_TITLE: 'אנחנו על זה',
  CONTACT_US_PAGE_BUTTON_LABEL: 'שלחו את הפנייה',
  CONTACT_US_ERROR_POPUP_TITLE: 'נתקלנו בבעיה',
  CONTACT_US_ERROR_POPUP: 'אופס, משהו לא עבד לנו.',
  CONTACT_US_ERROR_POPUP_DESC1: '.אופס, משהו לא עבד לנו',
  CONTACT_US_ERROR_POPUP_DESC2: '  תנו לנו רגע לטפל ונסו שנית מאוחר יותר ',
  CONTACT_US_SUCCESS_PAGE_BUTTON_LABEL: 'שלחו את הפנייה',
  CONTACT_US_SUCCESS_PAGE_DESC:
    'קיבלנו את הפנייה וניצור איתך קשר בימים הקרובים כדי לטפל בבקשה.',

  DETAILS_FIRST_NAME: 'שם פרטי',
  DETAILS_LAST_NAME: 'שם משפחה',
  DETAILS_EMAIL: 'אימייל',
  DETAILS_NOTE: 'פרטי הפנייה',
  DETAILS_EMAIL_PLACEHOLDER: 'לדוגמא: example@email.com',
  DETAILS_PHONE: 'טלפון נייד',
  EXIST: 'קיים',
  LEAVE_DETAILS_TITLE: 'בואו נדבר',
  LEAVE_DETAILS_FORM: 'טופס השארת פרטים',
  CHOOSE_SUBJECT_FORM: 'נושא הפנייה',
  CHOOSE_SUB_SUBJECT_FORM: 'תת נושא',
  EXPOSE_PRICE_INSTEAD: 'במקום',
  EXPOSE_PRICE_IS: 'מחיר האונליין הוא',
  EXPOSE_NUMBER_TITLE: 'רוצים את מחיר האונליין שלנו?',
  EXPOSE_NUMBER_SUBTITLE:
    'משאירים פרטים, מאמתים בקוד וחוזרים עם מחיר חדש – רק באונליין!',
  NOT_MANDATORY: 'לא חובה',

  FINISH_LEAVE_DETAILS_TITLE: 'מתרגשים? אנחנו בדרך',
  LOBBY_LEAVE_DETAILS_TITLE: 'השאירו לנו פרטים',
  LOBBY_BUDGET_TITLE1: 'המכוניות שלנו,',
  LOBBY_BUDGET_TITLE2: 'במחיר שלך',
  LOBBY_LEAVE_DETAILS_DESC: 'צריכים את העזרה שלנו? צרו קשר',
  FINISH_LEAVE_DETAILS_SUBTITLE:
    'ניצור איתכם קשר ביומיים הקרובים כדי לדבר על כל מה שחשוב ומעניין. ',
  LEAVE_DETAILS_SUBTITLE:
    'תשאירו לנו את כל הפטפוטים! ספרו לנו מי אתם ואנחנו כבר ניצור איתכם קשר',
  LEAVE_DETAILS_BUTTON: 'צרו איתי קשר',
  SEND_LEAD_DETAILS_BUTTON: 'שלחו את הפנייה',
  FINISH_LEAVE_DETAILS_PRICE_TITLE: 'מושלם! מחיר האונליין מחכה לכם באתר',
  FINISH_LEAVE_DETAILS_PRICE_SUBTITLE:
    'ניצור איתכם קשר ביומיים הקרובים עם כל מה שחשוב לדעת.',
  FINISH_LEAVE_DETAILS_BUTTON: 'חזרו לעמוד הבית',
  BACK_TO_CONTACT_US: 'חזרה ליצירת קשר',
  FINISH_LEAVE_DETAILS_PRICE_BUTTON: 'חזרו לעמוד המכונית',
  WHAT_WILL_HAPPEN_TITLE: 'ומה עכשיו?',
  FINISH_LEAVE_DETAILS_INSTRUCTION_ONE: 'נתאם יום ושעה ביחד',
  FINISH_LEAVE_DETAILS_INSTRUCTION_TWO: 'הקפה והחנייה עלינו',
  FINISH_LEAVE_DETAILS_INSTRUCTION_THREE: 'נסגור את כל הפרטים פנים מול פנים',

  nonHybridElectricWarrantyDesc1:
    'תכולת אחריות היצרן ל-{years} שנים או {km} ק"מ, המוקדם מבניהם.\nאחריות מלאה על כלל מכללי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.\nהאחריות תינתן כמפורט בחוברת האחריות.',
  hybridElectricWarrantyDesc1:
    'תכולת אחריות היצרן ל-{years} שנים או {km} ק"מ, המוקדם מבניהם.\nאחריות מלאה על כלל מכללי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.\nהאחריות למערכות ההנעה והסוללה הן ל-{batteryKm} שנים או {batteryYears} ק"מ, המוקדם מבניהם. האחריות תינתן כמפורט בחוברת האחריות.',
  hybridElectricWarrantyDesc: 'ק״מ המוקדם מביניהם {years} שנים או {km}',

  nonHybridElectricWarrantyDesc: 'ק״מ המוקדם מביניהם {years} שנים או {km}',
  hybridElectricWarrantyFallbackDesc: 'ק״מ המוקדם מביניהם {years} שנים או {km}',
  nonHybridElectricExtendedWarrantyDesc:
    'ק״מ המוקדם מביניהם {years} שנים או {km}',
  hybridElectricExtendedWarrantyDesc: 'ק״מ המוקדם מביניהם {years} שנים או {km}',
  hybridElectricWarrantyFallbackDesc1:
    'תכולת אחריות היצרן ל-{years} שנים או {km} ק"מ, המוקדם מבניהם.\nאחריות מלאה על כלל מכללי הרכב למעט חלקי טיפול או המועדים לשחיקה ו/או בלאי טבעי.\n',
  nonHybridElectricExtendedWarrantyDesc1:
    'תכולת הארכת האחריות ל-{years} שנים או {km} ק"מ, המוקדם מבניהם: הארכת כתב האחריות המקורי של היצרן לתקופה נוספת, בתכולה זהה למקורית- ניתנת ע"י היבואן. האחריות מותנית בין היתר בביצוע שגרת טיפולים ברכב במוסך מורשה יבואן בכל תקופת האחריות.',
  hybridElectricExtendedWarrantyDesc1:
    'תכולת הארכת האחריות ל-{years} שנים או {km} ק"מ, המוקדם מבניהם: הארכת כתב האחריות המקורי של היצרן לתקופה נוספת, בתכולה זהה למקורית- ניתנת ע"י היבואן. האחריות מותנית בין היתר בביצוע שגרת טיפולים ברכב במוסך מורשה יבואן בכל תקופת האחריות. על מערכות ההנעה והסוללה יש אחריות יצרן ללא הארכה, ע"פ כתב האחריות.',
  CREATE_MEETING_NO_TERRITORIES_FOUND_ERROR: 'לא נמצאו תאריכים ושעות',
  CREATE_MEETING_DETAILS_BUTTON: 'בחרו תאריך ושעה',
  CREATE_MEETING_TITLE: 'איזה כיף, נפגשים!',
  FINISH_CREATE_MEETING_TITLE: 'קבענו!',
  CREATE_MEETING_SUBTITLE: 'אל דאגה, נזכיר לך יום לפני הפגישה',
  CREATE_PHONE_MEETING_SUBTITLE:
    'קיבלנו את הפנייה וניצור איתך קשר בימים הקרובים כדי לטפל בבקשה.',
  FINISH_CREATE_MEETING_INSTRUCTION_ONE: 'ניפגש ביום שקבענו',
  FINISH_CREATE_MEETING_INSTRUCTION_TWO: 'הקפה והחנייה עלינו',
  FINISH_CREATE_MEETING_INSTRUCTION_THREE: 'נסגור את כל הפרטים',

  CREATE_MEETING_BUTTON: 'קבעו פגישה',
  CREATE_MEETING_BACK_BUTTON: 'חזור אחורה',

  CREATE_MEETING_SELECT_TERRITORY: 'בחר אולם',
  CREATE_MEETING_SELECT_DAY: 'בחר יום',
  CREATE_MEETING_SELECT_HOUR: 'בחר שעה',
  CREATE_MEETING_SELECT_CITY: 'בחר עיר',
  CREATE_MEETING_SELECT_CITY_TITLE: 'בחרו עיר לפגישה',
  CREATE_AGENCY_SELECT_CITY: 'הסניף הקרוב אליך',
  CREATE_MEETING_NO_CITIES_FOUND: 'לא נמצאו תוצאות',
  PAYMENT_FLOW_SUCCESS_PAGE_HEADER: 'ההזמנה התקבלה וההתרגשות מתחילה!',
  PAYMENT_FLOW_SUCCESS_PAGE_HEADER_NEW: 'תודה! עכשיו אנחנו גם מתחילים להתרגש',
  PAYMENT_FLOW_SUCCESS_PAGE_FAILED_ORDER_HEADER: 'כמעט סיימנו :)',
  PAYMENT_FLOW_SUCCESS_PAGE_FAILED_ORDER_HEADER_NEW:
    'תודה! עכשיו אנחנו גם מתחילים להתרגש',
  PAYMENT_FLOW_SUCCESS_PAGE_BODY: 'עכשיו אנחנו יכולים לטפל בהזמנה שלך.',
  PAYMENT_FLOW_SUCCESS_PAGE_BODY_NEW:
    'קבלנו את התשלום, זהו הרכב שמור לכם מספר עסקה:',
  PAYMENT_FLOW_SUCCESS_PAGE_FAILED_ORDER_BODY:
    'הפרטים שלך נקלטו אך ההזמנה לא הושלמה.',
  PAYMENT_FLOW_SUCCESS_PAGE_TRANSACTION_NUMBER: ' מספר עסקה :',
  PAYMENT_FLOW_SUCCESS_PAGE_FAILED_ORDER_APPROVAL:
    ' מבטיחים לחזור בהקדם לסיום התהליך.',
  PAYMENT_FLOW_SUCCESS_PAGE_FAILED_ORDER_APPROVAL_NEW:
    'שלחנו את הקבלה ואת אישור התשלום לפלאפון שלך! ',
  PAYMENT_FLOW_SUCCESS_PAGE_SEND_APPROVAL:
    'שלחנו את הקבלה ואת אישור התשלום למייל ולטלפון הנייד ',
  PAYMENT_FLOW_SUCCESS_PAGE_CONTACT_MESSAGE:
    'שימו לב️ שניצור איתכם קשר ביומיים הקרובים כדי לתאם הגעה להמשך פנים מול פנים',
  PAYMENT_FLOW_SUCCESS_PAGE_EXPLAIN_HEADER: 'מה קורה עכשיו?',
  PAYMENT_FLOW_SUCCESS_PAGE_EXPLAIN_DAY_AND_HOUR: 'נתאם יום ושעה ביחד',
  PAYMENT_FLOW_SUCCESS_PAGE_EXPLAIN_COFFEE: 'הקפה והחנייה עלינו',
  PAYMENT_FLOW_SUCCESS_PAGE_EXPLAIN_FACE_TO_FACE:
    'נסגור את כל הפרטים פנים מול פנים',
  MOVE_TO_HOME_PAGE_BTN: 'חזרה לעמוד הבית',
  TO_HOME_PAGE: 'לעמוד הבית',
  YOU_CAN_CHOOSE_MULTIPLE: '*אפשר לבחור גם כמה',
  UNDERSTOOD_ALL: 'הבנתי הכל',
  LEASING_DEAL_DETAILS: 'פרטי עסקת ליסינג',
  LEASING_PRICE_LIST: 'מחיר מחירון ',
  EXTENDED_PLAN: 'מסלול פרימיום',
  NORMAL_PLAN: 'מסלול בסיסי',
  TRADE_IN: 'טרייד-אין',
  EXTENDED_INSURANCE: 'ביטוח מורחב',
  MONTH_IN_YEAR: 'חודשים בשנה',
  DOWNLOAD_TECH_SPEC: 'להורדת המפרט',
  TECH_SPEC_LINK: 'קישור למפרט',
  TECH_SPEC_SUBTITLE_TEXT: 'המפרט נלקח ממאגר קארזון ובאחריותו הבלעדית',
  SAFETY_TITLE_ACCORDION: 'בטיחות וזיהום אוויר',
  TECH_SPEC_TITLE_ACCORDION: 'מפרט טכני של רמות הגימור שלנו',
  TECH_SPEC_SUBTITLE_FINISHED_LEVEL: 'מפרט טכני לרמת גימור',
  TECH_SPEC_ACCORDION_ACCESSORIES_TITLE: 'אביזרים ונוחות',
  TECH_SPEC_ACCORDION_ENGINE_TITLE: 'מנוע וביצועים',
  TECH_SPEC_ACCORDION_CAR_SIZE_TITLE: 'מידות',
  SAFETY_BULLET_RULE_dmvElectricWindows: 'מספר חלונות חשמל',
  SAFETY_BULLET_RULE_dvmIsAirConditioner: 'מזגן',
  SAFETY_BULLET_RULE_dmvIsSagsogetWheels: 'חישוקים קלים',
  SAFETY_BULLET_RULE_dmvIsSunroof: 'חלון בגג',
  SAFETY_BULLET_RULE_dmvIsBox: 'ארגז',
  SAFETY_BULLET_RULE_dmvChassis: 'מרכב',
  SAFETY_BULLET_RULE_dmvTotalWeight: 'משקל כולל',
  SAFETY_BULLET_RULE_dmvHeight: 'גובה',
  SAFETY_BULLET_RULE_dmvNumbersOfDoors: 'מספר דלתות',
  SAFETY_BULLET_RULE_dmvNumberOfSeats: 'מספר מושבים',
  SAFETY_BULLET_RULE_dmvTowWithBreak: 'כושר גרירה עם בלם',
  SAFETY_BULLET_RULE_dmvTowWithoutBreak: 'כושר גרירה ללא בלם',
  SAFETY_BULLET_RULE_dmvEngineCapacity: 'נפח מנוע',
  SAFETY_BULLET_RULE_dmvHorsepower: 'כח סוס',
  SAFETY_BULLET_RULE_dmvPropulsion: 'הנעה',
  SAFETY_BULLET_RULE_dmvPropulsionType: 'סוג הנעה',
  SAFETY_BULLET_RULE_dmvIsAutomatic: 'תיבת הילוכים אוטומטית',
  SAFETY_BULLET_RULE_dmvFuel: 'סוג דלק',
  SAFETY_BULLET_RULE_dmvAirPollutionScore: 'קבוצת זיהום',
  TOOLTIP_BUTTON_TEXT: 'כפתור היועץ הנאמן שלנו ילווה אותך לאורך כל  הדרך',
  PAYMENT_FLOW_FAILED_PAYMENT: `אחד או יותר מהשדות שהזנת לא נכונים. לא התבצעה עסקת חיוב לאשראי שהזנת`,
  LEGAL_POINTS_TITLE: 'דברים שכדאי לדעת',
  LEGAL_POINTS_TITLE_NEW: 'כל הנק׳ המשפטיות שצריך לדעת',
  WE_HERE_FOR_YOU: 'אנחנו כאן, זמינים בשבילך',
  DESIGN_YOU_CAR_POINTS: [' ייתכן כי צבעים מסוימים יהיו כרוכים בתוספת תשלום'],
  DESIGN_YOU_CAR_COLOR_ADDITIONAL_PRICE: 'בתוספת: ',
  ACCESSORIES_POINTS: [
    'אחריות על האביזרים המוצעים למכירה תינתן בהתאם למדיניות הספק.',
    ' באחריות הרוכש לוודא התאמה של האביזרים לצרכיו ולשימושיו.',
  ],
  SUMMARY_LEGAL_POINTS: [
    'המחירים המוצגים באתר נכונים למועד שריון העסקה ועשויים להשתנות בהתאם לשינויים בעלות המכונית.',
    'אספקת הרכב בפועל מותנת בחתימה על טפסי ההזמנה וזמינות המכונית במלאי.',
  ],
  SUCCESS_PAYMENT_LEGAL_POINTS: [
    'ניתן לבטל את שיריון הרכב בתוך 14 ימי עסקים ממועד השיריון באתר. במקרה של ביטול כאמור, יושבו דמי שיריון הרכב במלואם.',
    'אי השלמת הזמנה בתוך פרק הזמן הנקוב באתר, עשויה להוביל לביטול שיריון הרכב.',
  ],
  PLANS_AND_MANAGEMENT_POINTS: [
    'טיפולים תקופתיים בהתאם למפרט יבואן בלבד ללא תוספות. כיסוי הנזקים והבלמים בכפוף להסכם.',
  ],
  BREADCRUMB_HOME: 'דף הבית',
  BREADCRUMB_LEASE: 'ליסינג',
  BREADCRUMB_NEW_CAR: 'רכב חדש',
  BREADCRUMB_USED_CAR: 'יד שניה',
  BREADCRUMB_LISTINGS: 'לוח רכבים',
  BREADCRUMB_NAVIGATION: 'מיקומך באתר',
  NEW_CAR_INTERIOR_DESIGN_TITLE: 'עיצוב פנים',
  NEW_CAR_INTERIOR_DESIGN_SUBTITLE: 'גלו עיצוב פנים מודרני וספורטיבי',
  NEW_CAR_CONFIGURATOR_TITLE1: 'רוצים לחסוך זמן?',
  NEW_CAR_CONFIGURATOR_SUBTITLE1:
    'מתאימים ומשריינים את הרכב באתר וחוסכים זמן באולם התצוגה',
  NEW_CAR_CONFIGURATOR_SUBTITLE2:
    'מתאימים ומשריינים את העסקה באתר וחוסכים זמן יקר',
  NEW_CAR_CONFIGURATOR_TITLE: 'סגורים על עצמכם?',
  NEW_CAR_CONFIGURATOR_SUBTITLE: 'התאימו את הרכב בקלות',
  NEW_CAR_CONFIGURATOR_BUTTON: 'להתאמה ושריון הרכב',
  NEW_CAR_CONFIGURATOR_COLOR_TITLE: 'באיזה צבע תרצו את ה{model} שלכם?',
  NEW_CAR_SIMILAR_CARS_TITLE: 'דגמי {manufacturer} נוספים ב-freesbe',
  NEW_CAR_SIMILAR_CARS_SUBTITLE:
    'אוהבים את המותג? דגמים נוספים זמינים לכם באתר',
  LEASING: 'ליסינג',
  LEASING_SEO_TITLE: 'בליסינג פרטי',
  LEASING_SEO_DES: 'בליסינג',
  FUNDING: 'מימון',
  FUNDING_SEO_TITLE: 'במימון',
  GEAR_AUTOMATIC: 'אוטומטי',
  GEAR_MANUALLY: 'ידני',
  BUYING: 'קנייה',
  ONLINE_PRICE: 'מחיר אונליין',
  BUYING_SEO_DESCRIPTION: 'למכירה',
  BUYING_SEO_TITLE: 'חדשה למכירה',
  BUYING_SEO_DES: 'למכירה',
  NEW_SHEKEL_SIGN: '₪',
  KMS: 'ק״מ',
  LITRES: 'ליטרים',
  PHOTOS_ILLUSTRATION_ONLY: 'התמונה להמחשה בלבד.',
  LEGAL_INFO_LEASING: '',
  LEGAL_INFO_FUNDING: `בכפוף לתשלום מקדמה. התשלום החודשי מחושב לפי ריבית שנתית נומינלית משתנה של פריים + 2.95% ואינו כולל עלות הקמת הלוואה בסך 350 ₪ + 1% מסכום ההלוואה ועמלת גביה חודשית בסך 2.1 ₪.
הצעת ההלוואה תינתן בכפוף לעמידה בתנאי החיתום, בהתאם לנתוניו האישיים של כל לקוח ובכפוף לשיקול הדעת הבלעדי ולתנאים שיקבעו על ידי הגוף המממן – אוטוקאש שרותי מימון בע"מ, בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב  בריבית פיגורים והליכי הוצאה לפועל.`,
  LEGAL_INFO_FUNDING_USED_CAR:
    'התשלום החודשי מחושב בהתאם לריבית קבועה נומינלית בשיעור של 9.85% שנתית צמוד למדד המחירים לצרכן ואינו כולל הקמת הלוואה ועמלת גביה חודשית. מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  LEGAL_INFO_BUYING: 'מחיר המכונית כולל מע״מ ואגרת רישוי הינו ',
  TOOLTIP_ZERO:
    'המחיר המוצג הינו מחיר התחלתי לרכב חדש ללא מחיר אגרות רישוי. הרכב המוצע הינו רכב 0 ק"מ מבעלות ראשונה. מינימום 1 יחידות במלאי ועד גמר המלאי.',
  EDIT_BUTTON_TITLE: 'סיכום הזמנה',
  EDIT_BUTTON: 'לעריכה',
  EDIT_DETAILS_BUTTON: 'לעדכון פרטים',
  COLOR: 'צבע',
  APPROVAL_CODE_TITLE: 'קוד אישור עקרוני למימון',
  COLOR_RED: 'אדום',
  COLOR_GRAY: 'אפור',
  COLOR_BLUE: 'כחול',
  COLOR_SILVER: 'כסף',
  COLOR_ORANGE: 'כתום',
  COLOR_WHITE: 'לבן',
  COLOR_BLACK: 'שחור',
  COLOR_GOLD: 'זהב',
  COLOR_BROWN: 'חום',
  COLOR_GREEN: 'ירוק',
  COLOR_YELLOW: 'צהוב',
  COLOR_PINK: 'ורוד',
  COLOR_PURPLE: 'סגול',
  PLANE_AND_MANAGEMENT_TIP_TITLE: 'מה זה בכלל ליסינג?',
  PLANE_AND_MANAGEMENT_TIP_DESCRIPTION:
    'ליסינג הוא פתרון אידיאלי למי שרוצה לנהוג ברכב חדש בתשלום חודשי קבוע וידוע מראש. ב-freesbe המסלול ל-36 חודשים וניתן להחליט בסוף התקופה אם לקנות את הרכב בהנחה ממחירון יצחק לוי המלא (ללא הפחתות) או להחליף לרכב חדש מהניילונים.',
  TIME_FRAME_TIP_TITLE: 'לורם איפסום דולור סיט אמט,י.',
  TIME_FRAME_TIP_DESCRIPTION:
    'מסלולי הליסינג שלנו מציעים לך גמישות ואת האפשרות לבחור בעצמך כל כמה זמן להתחדש במכונית - כל שנתיים וחצי או כל שלוש שנים',
  ACCESSORIES_AND_ADVANCE: 'אביזרים ומקדמה',
  PRE_PAYMENT: 'דמי שריון',
  ONE_TIME_PAYMENT: 'תשלום חד פעמי',
  ADDITIONAL_PAYMENT_AND_ADVANCE: 'חד פעמי',
  ADDITIONAL_PAYMENT: ' תוספות',
  MONTHLY_PAYMENT: 'תשלום חודשי',
  MONTHLY: 'חודשי',
  LEFT_TO_PAY: 'נותר לתשלום',
  REFOUND_AND_REPLACE: 'זיכוי והחלפה',
  END_PAYMENT: 'תשלום בסוף התקופה',
  LOAN_DETAILS_DEAL: 'פרטי עסקת מימון',
  FUNDING_CHECK: 'בדקו זכאותכם למימון',
  COMPARING_FOR_YOU: 'נשווה בשבילך',
  ADVANCE_PAYMENT: 'תשלום מקדמה',
  FINANCE_INFO_QA:
    'מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שירותי מימון בע&quot;מ ותנאיו מקבוצת XXX בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  FINANCE_WITH: 'מימון עם ',
  MONTHLY_PAYMENT_PRICE: 'תשלום חודשי:',
  FINANCE_WITH_DESCRIPTION:
    'חברת המימון שלנו קיימת משנת 2008 ומאפשרת לך לרכוש את המכונית בנוחות ובגמישות מקסימלית.\n תכנון מסלול ומבנה ההלוואה מותאם לך באופן הכי משתלם ויצירתי ונעשה בליווי מלא וצמוד.\n המימון אינו משפיע על מסגרת האשראי שלך בבנק ובכרטיסי האשראי.',
  FINANCE_WHO_ARE_WE: 'מי אנחנו?',
  FINANCE_CHECK: 'לבדיקת זכאות ',
  FINANCE_CHECK2: 'לבדיקת זכאות ',
  FINANCE_TO_LEAVE_CASE: 'לפניה בנושא מימון פעיל ',
  FINANCE_WHO_ARE_WE_DESC:
    'חברת המימון של קבוצת קרסו קיימת מ 2008 ובאמצעותה תוכל לרכוש את הרכב שלך במגוון מסלולים שמותאמים  לנוחות שלך באופן הכי משתלם ויצירתי ובגמישות מקסימלית לכל אורך ההלוואה ומגוון שירותים נוספים. \n המימון אינו משפיע על מסגרת האשראי שלך בבנק ובכרטיסי האשראי שלך. \n תכנון מסלול ההלוואה שלה והמבנה שלה מותאם בליווי מלא, קצר ונוח.',
  FINANCE_BENEFIT_WITHUS: 'היתרונות במימון איתנו',
  FINANCE_BOX_100: 'עד 100% מימון ממחיר הרכישה, גם האביזרים בפנים ',
  FINANCE_BOX_PAYMENTS: 'התשלום החודשי יותאם לגובה התשלום שנוח לך',
  FINANCE_BOX_FLIXIABLE:
    'אנחנו גמישים. אפשר לשנות תוכנית, לדלג על חודש או אפילו לדחות חלק מהסכום לסוף התקופה ',
  FINANCE_PAGE_CONTACT_US_EMAIL_ADDRESS: 'pniyot@freesbe.com',
  FINANCE_PAGE_CONTACT_US_LOCATION: 'מרכז לוגיסטי פארק ראם, ת.ד. 90',
  FINANCE_PAGE_CONTACT_US_TIMES_CONTENT: `  א'-ה' 08:00-16:00 `,
  FINANCE_PAGE_CONTACT_US_PHONE: '6020* שלוחה 4',
  FINANCE_PAGE_CONTACT_US_DETAILS: `אוטוקאש שרותי מימון בע"מ מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715.`,
  PRICIPLE_APPROVAL_TITLE: 'חמש שאלות קצרות לקבלת אישור עקרוני למימון  ',
  FUNDING_CTA_TITLE: 'מעוניינים במימון?',
  INSURANCE_FOOTER_MOBILE_TITLE: 'מתעניינים בביטוח רכב ?',
  CHARGING_SOLUTION_FOOTER_MOBILE_TITLE: 'מחפשים פתרונות טעינה?',
  FOUNDING_WHICH_CAR_TYPE: 'באיזה רכב אתם מעוניינים?',
  FOUNDING_HOW_MUCH_ADVANCED_PAYMENT: 'סכום המקדמה שתרצו לשלם',
  FOUNDING_HOW_MUCH_MONTHLY_LOAN: 'מספר תשלומי ההלוואה',
  FOUNDING_HOW_MUCH_END_OF_PERIOD_PAYMENT: 'מהו ההחזר החודשי שתרצו?',
  FUNDING_CALCULATOR_TITLE: 'מחשבון מימון לרכב חדש',
  FUNDING_CALCULATOR_TITLE_BY_CAR_TYPE: 'מחשבון מימון לרכב',
  FUNDING_CALCULATOR_TITLE_BY_CAR_TYPE_SUBTITLE:
    'מחשבון המימון שלנו יעזור לכם בתכנון או בהתאמת ההחזר החודשי שלכם',
  FUNDING_CONTINUE_BT: 'המשך',
  FUNDING_CALCULATOR_CTA_BT: 'לקבלת אישור עקרוני',
  FUNDING_CALCULATOR_DESCRIPTION:
    'מחשבון המימון שלנו יעזור לכם בתכנון או בהתאמת החזר החודשי שלכם',
  FUNDING_CALCULATOR_PRICE_QUESTION: 'מה עלות הרכב?',
  FUNDING_CALCULATOR_PRICE: 'היא עלות הרכב',
  FUNDING_CALCULATOR_DISCLAIMERS_1:
    'המחשבון הינו כלי עזר בלבד להערכת ההחזר החודשי על בסיס הנתונים שהוקלדו על ידך ואינו מהווה הצעה למימון. התשלום החודשי מחושב לפי ריבית שנתית נומינלית משתנה של פריים + 2.95% ואינו כולל עלות הקמת הלוואה ועמלת גביה חודשית.',
  FUNDING_CALCULATOR_DISCLAIMERS_2:
    'מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  FUNDING_CALCULATOR_USED_CAR_TIP:
    'אפשרות למימון הרכב בתשלום חודשי קבוע (צמוד למדד המחירים לצרכן)',
  FUNDING_CALCULATOR_USED_CAR_DISCLAIMERS_1:
    'המחשבון הינו כלי עזר בלבד להערכת ההחזר החודשי על בסיס הנתונים שהוקלדו על ידך ואינו מהווה הצעה למימון. התשלום החודשי מחושב בהתאם לריבית קבועה נומינלית בשיעור של {usedCarPrimeRate}% שנתית צמוד למדד המחירים לצרכן ואינו כולל הקמת הלוואה ועמלת גביה חודשית.',
  FUNDING_CALCULATOR_USED_CAR_DISCLAIMERS_2:
    'מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  FUNDING_CALCULATOR_PRICE_TITLE: 'חישוב פרטי ההלואה לרכב חדש בעלות של',
  FUNDING_CALCULATOR_SLIDER_ADVANCED_PAYMENT: 'סכום מקדמה',
  FUNDING_CALCULATOR_SLIDER_AFTER_PAYMENT: 'תשלום בסוף התקופה',
  FUNDING_CALCULATOR_SLIDER_NUMBER_OF_MONTHS: 'חודשים',
  FUNDING_CALCULATOR_PAYMENT_PER_MONTH: 'תשלום חודשי',
  FINANCE_EVERYTHING_IS_ONLINE:
    'מימון לרכב? זה הרבה יותר פשוט ממה שחשבתם! בדקו עכשיו את זכאותכם. ',
  FINANCE_DEAL_ELEMENTS_FIRST_HEADLINE:
    'אז בואו נעשה קצת סדר, ממה מורכבת עסקת מימון',
  FINANCE_DEAL_ELEMENTS_HEADLINE: 'קצת שאלות ותשובות, בשביל זה אנחנו פה',
  FINNANCE_DEAL_PREPAID: 'מקדמה',
  FINNANCE_DEAL_PREPAID_CONTENT:
    'הסכום שאותו נוח לך לשלם על חשבון מחיר הרכב והיתרה תיפרס להלוואה. אצלנו תוכל לקבל הלוואה עד 100% ממחיר הרכישה כולל האביזרים כך שלא תצטרך לשלם מקדמה בכלל.',
  FINNANCE_DEAL_MONTHLY_PAYMENT: 'תשלום חודשי',
  FINNANCE_DEAL_MONTHLY_PAYMENT_CONTENT:
    'התשלום החודשי שנוח לך להחזיר בכל חודש באופן שלא יכביד לך על התזרים החודשי וההוצאות ויאפשר לך להמשיך להתנהל בצורה תקינה. התשלום החודשי\n מכיל גם את הריבית שמחושבת בהלוואה ומשתנה בהתאם לגובה ההלוואה ומספר החודשים שתבקש להחזיר אותה. ניתן להפחית את גובה התשלום החודשי עם\n דחיית חלק מסכום ההלוואה לסוף התקופה.',
  FINNANCE_DEAL_INTEREST: 'ריבית',
  FINNANCE_DEAL_INTEREST_HEADLINE:
    'הריבית שלפיה מחושב החזר ההלוואה ומגולמת בתוך ההחזר החודשי.\n גובה הריבית משתנה בהתאם למסלול ההלוואה ומותאם לפי סוג הרכב שנבחר.',
  FINNANCE_DEAL_END_PERIOD_PAYMENT: 'תשלום בסוף התקופה',
  FINNANCE_DEAL_END_PERIOD_PAYMENT_CONTENT:
    'חלק מסכום ההלוואה שנדחה לסוף ההלוואה. את/ה בוחר אם תרצה לבנות את ההלוואה עם תשלום דחוי לסוף התקופה.\n גובה התשלום הדחוי גם הוא מושפע מסוג הרכב ותקופת ההלוואה ומשפיע על גובה ההחזר החודשי.',
  FINNANCE_DEAL_ALLOWENCE: 'תקופת ההלוואה ',
  FINNANCE_DEAL_ALLOWENCE_CONTENT:
    'מספר החודשים לפריסת ההלוואה ותשלום ההחזר החודשי. מספר החודשים להחזר ההלוואה הוא לבחירתך ומשפיע על גובה ההחזר החודשי.',
  FINANACE_QA: 'שאלות ותשובות',
  FINANACE_QA_WHO_ENTITLED: 'מי זכאי לקבל הלוואה לרכישת רכב?',
  FINANACE_QA_WHO_ENTITLED_A:
    'כל אדם, מעל גיל 24, העונה על דרישות בסיסיות של יכולת החזר ויציבות פיננסית זכאי לקבלת הלוואה.  חשוב לציין, בדיקת הזכאות נקבעת על ידי הגוף המממן ובידי מומחי החברה אשר קובעים את אחוז המימון המקסימלי שיועמד לרשות הלקוח.',
  FINANACE_QA_MAX_PRICE_LOAN: 'מה סכום ההלוואה המקסימלי?',
  FINANACE_QA_MAX_PRICE_LOAN_A:
    'סכום ההלוואה מוגבל ותלוי בערך הרכב אותו מעוניין הלווה לרכוש. סכום זה יכול להגיע לעד 100% ממחיר הרכב ומשתנה בהתאם לנתוני הלווה ומדיניות החברה.',
  FINANACE_QA_HOW_MUCH_PRESENT_LOAN:
    'בכמה אחוזי ריבית ניתנת הלוואה ובכמה תשלומים?',
  FINANACE_QA_HOW_MUCH_PRESENT_LOAN_A:
    'שיעור הריבית נקבע בעסקה בהתאם למסלול בו בחר הלקוח מתוך המסלולים האטרקטיביים שמעמידה עבורו חברת המימון. ההלוואות ניתנות בדרך כלל לתקופה של עד 60 חודשים.',
  FINANACE_QA_IS_THERE_LOAN_100: 'האם קיימות הלוואות של 100% מימון?',
  FINANACE_QA_IS_THERE_LOAN_100_A:
    ' שיעור המימון נקבע בכל עסקה בהתאם לנתוני הרכב והלקוח. האפשרות לשיעורי מימון גבוהים הינה עד 100% משווי הרכב, ונקבעת בהתאם לפרמטרים שונים הקשורים בלקוח.',
  FINANACE_QA_WHAT_IS_LIEN: 'מה זה שעבוד ומשכון לרכב?',
  FINANACE_QA_WHAT_IS_LIEN_A:
    'במתן הלוואה יש לדאוג לבטחונות נאותים שיבטיחו את פירעון ההלוואה. בהלוואה לרכישת רכב, משמש הרכב בטוחה כנגד ההלוואה. כאשר הרכב משועבד, אי אפשר למכור אותו לצד ג ללא הסרת השעבוד מהרכב.',
  FINANACE_QA_HOW_LIEN_HAPPENING: 'איך מתבצע שעבוד ומשכון לרכב?',
  FINANACE_QA_HOW_LIEN_HAPPENING_A:
    'השעבוד נרשם במשרד הרישוי ומופיע גם ברישיון הרכב וברשם המשכונות או רשם החברות. במקביל, נרשמת הערת שיעבוד בפוליסת הביטוח של הרכב המשועבד לטובת חברת המימון.',
  FINANACE_QA_HOW_TO_REMOVE_LIEN: 'כיצד ניתן להסיר את השעבוד ?',
  FINANACE_QA_HOW_TO_REMOVE_LIEN_A:
    'השעבוד מוסר כאשר ההלוואה מסתיימת והלווה סיים את כל התחייבויותיו בהלוואה או במקרים של פירעון מוקדם של יתרת ההלוואה. חברת אוטוקאש שרותי מימון מעבירה הוראת ביטול השעבוד לגורמים המשעבדים והממשכנים שיפעלו להסרת השעבוד.',
  FINANACE_QA_CONDITION_FOR_LOAN: ' מה הם התנאים לביצוע שינוי בהלוואה קיימת ?',
  FINANACE_QA_CONDITION_FOR_LOAN_A:
    'במידה ותרצו לבצע שינוי בהלוואה קיימת תוכלו להגיש בקשה זו. כל שינוי כפוף לאישור החברה וחתימתך על המסמכים הנדרשים. בחלק מהמקרים קיימת עמלת תפעול בגין הפעולה, עמלה זו תיגבה רק בביצוע השינוי בפועל ולאחר השלמת הטיפול בפניה',
  FINANACE_QA_EXTRA_FEE_CASES: 'באיזה מקרים אדרש לשלם עמלת תפעול ומהי העמלה ? ',
  FINANACE_QA_EXTRA_FEE_CASES_A:
    'קיימות עמלות תפעול אשר נגבות רק בביצוע השינוי בפועל ולאחר השלמת הטיפול בפניה, להלן רשימת המקרים והתעריפים: הארכת תקופת הלוואה 236 ₪ , קיצור תקופת הלוואה 186 ₪ , הורדת / הוספת לווה בעסקה ללא שינוי במשרד הרישוי 86 ₪ , הוספת לווה ובעלים ברישיון הרכב 470 ₪ , גריעת בעלים מרישיון הרכב 470 ₪',

  FINANACE_TERMS:
    'מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע״מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  ADDITIONS_INCLUDES: 'אביזרים כלולים בעסקה',
  CARRER_IN_FREESBE: 'קריירה ב-freesbe',
  AG_IN_FREESBE: 'ציוד ומיכון חקלאי',
  INSURANCE_PAGE_DESCRIPTION_TEXT:
    'כשאתם רוכשים רכב מקבוצת freesbe אתם בוודאי רוצים להיות בטוחים ומבוטחים כהלכה. לשם כך הוקמה קרסו סוכנות לביטוח (1989) בע"מ, מקבוצת freesbe.\n לתפיסתנו, החוויה המושלמת והאחראית ביותר חייבת לקשור שירות, אחריות וביטוח תחת קורת גג אחת.\n עיקר ההתמחות שלנו הוא בביטוח רכב תוך בדיקה מול חברות הביטוח השונות והמובילות בשוק ולטובת מתן הצעת המחיר הטובה, האטרקטיבית והמותאמת ביותר לרכבך ובשילוב תנאי יבואן בלעדיים! \n את אמנת השירות שלנו תוכלו תמיד למצוא',
  INSURANCE_PAGE_DESCRIPTION_LINK: 'כאן.',
  INSURANCE_PAGE_CHOOSE_INQUIRY: 'בחרו את נושא הפנייה',
  INSURANCE_PAGE_MEMBERS: 'לקוחות קיימים? ',
  CASE_CONTACT_LABEL: ' ',
  INSURANCE_JOIN_CTA_GET_PRICE: 'לקבלת הצעת מחיר',
  INSURANCE_JOIN_CTA_CONTACT_US: 'לפניה בנושא ביטוח קיים',
  INSURANCE_PAGE_JOIN_US_DESCRIPTION:
    'בקרסו סוכנות לביטוח (1989) בע"מ, מקבוצת freesbe התנאים והמחיר האטרקטיבי ביותר! הצטרפו אלינו עכשיו\n וחסכו מעצמכם כאבי ראש וטרחה מיותרת במקרה של נזק, תאונה או גניבה.',
  INSURANCE_SERVICES_DESCRIPTION: `אצלנו הכל תחת קורת גג אחת. מתמחים בביטוחי רכב אבל לא רק! תוכלו למצוא גם
ביטוח דירה, עסק, משכנתא, בריאות, חיים ונסיעות לחו"ל. מטרתנו , לעשות כל שאפשר כדי לסלול בעבורכם דרך נעימה, בטוחה ונטולת דאגות וזאת על ידי מתן תנאים בלעדיים , מחירים אטרקטיביים וברמת השירות האדיבה והמקצועית ביותר`,
  TALK_WITH_US_GLAD_TO_SEE_YOU: '\nדברו איתנו נשמח לעזור',
  TALK_WITH_US: 'דברו איתנו',
  PHONE_RESERVATION: 'לשריון טלפוני',
  TALK_WITH_US_CONF: 'חזרו אליי',
  FINISH_CONFIGURATOR: 'ועכשיו נשאר רק לשריין...',
  FINISH_CONFIGURATOR_DETAILS_2:
    'במידה ולא נשלים את העסקה- <b>המקדמה תוחזר.</b>',
  FINISH_CONFIGURATOR_DETAILS_3:
    'עוד לא הגעת להחלטה? אפשר ליצור קשר ונתקדם משם.',
  FINISH_CONFIGURATOR_DETAILS_1:
    'בתשלום דמי שריון על סך <b>₪2,000</b> נשמור עבורך את תנאי העסקה ופרטי המכונית שהתאמת אישית.',
  FINISH_CONFIGURATOR_LEASE_DETAILS_1:
    'בתשלום דמי שריון על סך <b>₪800</b> נשמור עבורך את תנאי העסקה ופרטי המכונית שהתאמת אישית.',
  FEEL_FREE_TO_REACH_OUT: 'תרגישו בנוח לפנות אלינו ישירות',
  TALK_WITH_US_S: 'דברו איתנו !',
  INSURANCE_TALK_WITH_US: 'להצטרפות',
  INSURANCE_CONTACT_SERVICE: 'לשירות או חידוש ביטוח',
  SPECIAL_GOODS_WITH_OUR_INSURANCE: 'הטבות מיוחדות לחברי הביטוח שלנו',
  INSURANCE_SERVICES_TITLE:
    'התנאים המצוינים הינם בכפוף לתנאי הפוליסה ולכתב השירות ט.ל.ח',
  DIFFERENCE_BETWEEN_INSURANCES: 'מה ההבדל בין סוגי הביטוח השונים',
  JOIN_US: 'הצטרפו אלינו',
  ALL_INSURANCE_SERVICES: 'כל שירותי הביטוח שלנו',
  CAR_INSURANCE_WITH: 'ביטוח רכב עם',
  INSURANCE: 'ביטוח',
  CHERY: "צ'רי",
  CREATED_BY: 'Created by',
  ABOUT_TITLE: 'הכל במקום אחד',
  FAMILY_CAR_PROMO_BOTTOM_TITLE: 'בקרוב באתר...',
  ABOUT_DESCRIPTION:
    'מעניקה מגוון שירותי רכב ללקוח הפרטי והעסקי באתר האינטרנט של המותג ובכמאה אתרים בפריסה ארצית. ב-freesbe ניתן לבדוק, להשוות ולרכוש רכב פרטי או משומש, בתשלום מלא או במבחר מסלולי מימון, ליסינג וכמובן להוסיף ביטוח רכב, אביזרים וטריידאין במידת הצורך.',
  WHO_WE_ARE_TITLE: 'מי אנחנו?',
  WHO_WE_ARE_DESCRIPTION:
    'מאחורי freesbe עומדת קבוצת קרסו מוטורס, יבואנית וותיקה ומוכרת, הפועלת החל משנת 1933 בעולמות הרכב בישראל. שירותי הביטוח, הליסינג והמימון נעשים בבית החברה ומאפשרים ללקוח להנות מתהליך יעיל ומשתלם ללא מתווכים וגופים חיצוניים. עם השקת freesbe אוחדו שירותי המימון, הליסינג, מכר המכוניות המשומשות והביטוח בקבוצת קרסו מוטורס תחת freesbe, לרבות פעילות ליס פור יו, קל אוטו, קרסו טרייד, קרסו ביטוחים ואוטוקש שירותי מימון.',
  CHECK_CARS: 'לצפייה במכוניות',
  FOLLOW_US_ON_FB: 'עקבו אחרינו בפייסבוק',
  FOLLOW_US_ON_ING: 'עקבו אחרינו באינסטגרם',
  FOLLOW_US_IN: 'עקבו אחרינו בלינק דין',
  FOLLOW_US_YT: 'עקבו אחרינו ביוטיוב',
  CLOSE_SF: 'סגור מסך עמוד חיפוש',
  RETURN_PREV_STEP_SF: 'חזרה לשלב קודם',
  CLEAR_FILTER: 'מחק סינון ',
  RP_HEADER_ACCESSIBILITY: 'עמוד תוצאות',
  CLOSE_TOOLTIP: ' סגור מגירת תוכניות רכישה',
  ADD_TO_FAV: 'הוספת רכב למועדפים',
  FAVORITES: 'מועדפים',
  IMG_GALLERY: 'גלריית תמונות',
  ACCESSIBILITY_FUNDING_CALCULATORS_SLIDER: ' בחר ',
  FINANCE_PAGE_VALUE_OF_CAR: 'ערך הרכב צריך להיות בין',
  PROMOTION_CAR_PAGE_TITLE: 'יש כאן משהו מיוחד !',
  PROMOTION_CAR_PAGE_DESCRIPTION:
    'יש לנו מחיר בהצעה ייחודית. המחיר זול יותר מהממוצע של רכבים חשמליים מאותו השנתון. כדי למהר כי נחטף מהר',
  HOME_PAGE: 'עמוד בית',
  SEARCH_FLOW: 'פלואו חיפוש',
  CONFIGURATOR: 'קונפיגורטור',
  FREESBE_LOGO_ALT_TEXT: 'פריסבי - ליסינג, השכרה ומכירת רכב, לעמוד הבית',
  FREESBE: 'פריסבי',
  SHARE: 'שיתוף',
  SHARE_BY_WHATSAPP: 'שלחו בוואטסאפ',
  SHARE_BY_COPY_LINK: 'העתקת קישור',
  SHARE_BY_COPY_LINK_TOAST_TEXT: 'נהדר! העתקנו את הלינק ללוח שלך',
  SHARE_BY_MAIL: 'שליחה במייל',
  COPY: 'Copy',
  EMAIL: 'Email',
  WHATSAPP: 'WhatsApp',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD: 'שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_NAME: 'שם פרטי הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_LAST: 'שם משפחה הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_EMAIL: 'אימייל הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_ID: 'ת"ז הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_PHONE: 'טלפון נייד הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_BRANCHES: 'סניף הינו שדה חובה',
  VALIDATION_HELPER_TEXT_REQUIRED_FIELD_ADDRESS: 'כתובת הינו שדה חובה',
  VALIDATION_HELPER_TEXT_INVALID_FIRST_NAME: 'נא להזין שם פרטי תקין',
  VALIDATION_HELPER_TEXT_INVALID_MAX_LEN_FIRST_NAME:
    'שם פרטי יכול להכיל עד 30 תווים',
  VALIDATION_HELPER_TEXT_INVALID_LAST_NAME: 'נא להזין שם משפחה תקין',
  VALIDATION_HELPER_TEXT_INVALID_MAX_LEN_LAST_NAME:
    'שם משפחה יכול להכיל עד 30 תווים',
  VALIDATION_HELPER_TEXT_INVALID_EMAIL: 'נא להזין כתובת אימייל תקינה',
  VALIDATION_HELPER_TEXT_INVALID_ID: 'נא להזין ת"ז תקינה',
  VALIDATION_HELPER_TEXT_INVALID_PHONE_NUMBER: 'נא להזין מספר נייד',
  VALIDATION_HELPER_TEXT_INVALID_LICENSE_NUMBER: 'נא להזין מספר רישוי תקין',
  VALIDATION_HELPER_TEXT_INVALID_ADDRESS: 'נא להזין רחוב ומספר עד 40 תווים',
  SEARCH_FLOW_GENERAL: 'מתלבטים מה לקנות',
  TECH_SPEC_GENERAL: 'כללי',
  TECH_SPEC_ENGINE_AND_PERFORMANCE: 'מנוע וביצועים',
  TECH_SPEC_ACCESSORIES_AND_CONVENIENCE: 'אביזרים ונוחות',
  TECH_SPEC_COMMUNICATION_AND_CONNECTIVITY: 'תקשורת וקישוריות',
  TECH_SPEC_DIMENSIONS_AND_MEASURES: 'מידות ומדדים',
  TECH_SPEC_SAFETY: 'בטיחות',
  TECH_SPEC_AIR_POLLUTION_AND_ENERGY: 'זיהום אוויר וצריכת אנרגיה',
  TECH_SPEC_ENGINE_SIZE: 'סמ״ק',
  TECH_SPEC_ENGINE: 'מנוע',
  TECH_SPEC_FINISH_LEVEL_TITLE: 'מפרט טכני לרמות גימור',
  TECH_SPEC_VERSIONS: 'גרסאות',
  AC_BACK_EXIT: 'מזגן - פתחים מאחור',
  AC_TYPE: 'מזגן - איזורים, בקרת אקלים',
  BOOT_POWERED: 'פתיחה חשמלית דלת תא מטען',
  DRIVER_CHAIR: 'כסא נהג - חשמלי או לא',
  GAUGE_CLUSTER: 'סוג לוח מכוונים',
  GAUGE_CLUSTER_HEADS_UP_DISPLAY: 'תצוגה עילית',
  NEAR_DRIVER_CHAIR: 'כסא ליד נהג - חשמלי או לא',
  PARKING_BREAK_TYPE: 'בלם חניה חשמלי',
  DRL: 'תאורת יום',
  AMBIENTLIGHT: 'תאורת אווירה',
  FRONTLIGHT: 'סוג אורות קדמיים',
  BACKLIGHT: 'סוג אורות אחוריים',
  DOORSLOCKING: 'נעילה מרכזית',
  BACKDOORWINDOWBLACKED: 'חלונות אחורים מושחרים',
  CAMERA: 'מצלמת רכב',
  WINDMIRRORSFOLDED: 'קיפול חשמלי מראות צד',
  SUNROOF: 'חלון בגג',
  WHEELS: "סוג ג'אנטים",
  POWER_ASSISTED_STEERING: 'הגה כוח',
  POWER_WINDOW_NUMBER: 'מספר חלונות חשמליים',
  UPHOLSTERY: 'ריפוד',
  WIPERS_SENSOR: 'חיישני גשם',
  ENGINE_CONSUMPTION: 'הערכת קארזון קילומטר לליטר',
  MOTOR_RANGE: 'טווח נסיעה (ק"מ)',
  POLLUTION_GROUP: 'דרגת זיהום',
  BLUETOOTH: 'חיבור BT',
  MOBILE_CONNECTIVITY: 'שיקוף אנדרואיד/אפל',
  MULTIMEDIA: 'מולטימדיה',
  WIRELESS_CHARGING: 'משטח טעינה אלחוטית',
  BODY_TYPE: 'צורת גוף',
  BOOT_VOLUME_LITER: 'נפח תא מטען (ליטר)',
  DOORS_NUMBER: 'מספר דלתות',
  FRONT_TIERS: 'גודל צמיגים קדמיים',
  HEIGHT_MM: 'גובה (מ"מ)',
  LENGTH_MM: 'אורך (מ"מ)',
  REAR_TIERS: 'גודל צמיגים אחוריים',
  TOTAL_WEIGHT: 'משקל כולל (ק"ג)',
  WHEEL_BASE_MM: 'מרווח סרנים (מ"מ)',
  WIDTH_MM: 'רוחב (מ"מ)',
  ACCELERATION_TO_100: 'האצה מ 0 - 100',
  ENGINE_POWER: 'הספק כוח סוס',
  ENGINE_TYPE: 'סוג מנוע',
  ELECTRIC: 'חשמלי',
  ENGINE_VOLUME: 'נפח מנוע',
  IS_AUTOMATIC: 'תיבת הילוכים אוטומטית',
  TOP_SPEED: 'מקסימום מהירות',
  WHEEL_DRIVE_TYPE: 'הנעה קדמית / אחורית',
  FINISH_LEVEL_NAME: 'רמת גימור',
  MANUFACTURER_NAME: 'שם יצרן',
  MODEL_NAME: 'שם מודל',
  REGISTRATION_FEE_GROUP: 'קבוצת אגרה',
  SEATS_NUMBER: 'מספר מושבים',
  SERIES_NAME: 'שם סדרה',
  YEAR: 'שנת עליה לכביש',
  ABS: 'ABS',
  ACC: 'בקרת שיוט אדפטיבית',
  AEB: 'עצירה אוטונומית',
  AEB_REVERSE: 'מערכת עצירה אוטו לרוורס',
  AFTERMARKERSAFETYSYSTEM: 'התקנה מקומית למערכת בטיחות מתקדמת',
  PROXIMITYSENSORS: 'חיישני קרבה',
  BLIS: 'התראה לשטחים מתים',
  RCTA: 'התראה להתנגשות בתנועה אחורית חוצה',
  EBA: 'מערכת עזר לבלימת חירום',
  AHB: 'אור גבוה אוטומטי',
  TSR: 'מזהה תמרורים',
  AUTOMATICLIGHTON: 'מערכת הדלקת אורות אוטומטית',
  PEDESTRIANSIDENTIFICATIONSYSTEM: 'התראה על הולכי רכב',
  FCW: 'התראה על התנגשות מקדימה',
  SBR: 'התראה לחגורת בטיחות',
  ESP: 'בקרת יציבות',
  LDWS: 'התראה לסטייה מנתיב',
  TPMS: 'בקרת לחץ צמיגים',
  AIR_BAGS_NUMBER: 'מספר כריות אוויר',
  SAFETY_LEVEL: 'דרגת ביטיחות משרד התחבורה',
  SAFETY_STARS_NUMBER: 'ציון במבחן בטיחות ',
  SAFETY_STARS_NUMBER_EUR: 'ציון במבחן בטיחות EURONCAP',
  SAFETY_STARS_YEAR: 'שנת מבחן בטיחות',
  INSURANCE_PAGE_CONTACT_US_DETAILS: `קרסו סוכנות לביטוח (1989) בע"מ ח.פ 511430670 בעלת רישיון סוכן
  ביטוח תאגיד מטעם רשות שוק ההון ביטוח וחיסכון , מורשית לעסוק
  בענפי הביטוח כללי, פנסיוני וימי .`,
  INSURANCE_PAGE_CONTACT_US_EMAIL_ADDRESS: 'INSH@FREESBE.COM',
  INSURANCE_PAGE_CONTACT_US_LOCATION: 'מרכז לוגיסטי פארק ראם, ת.ד. 90',
  INSURANCE_PAGE_CONTACT_US_TIMES_CONTENT: `  א'-ה' 08:00-16:00 `,
  INSURANCE_PAGE_CONTACT_US_TIMES_CONTENT2: `  ימי ו' 08:00-11:00`,
  INSURANCE_PAGE_CONTACT_US_TIMES_HEADER: `שעות פעילות: `,
  INSURANCE_PAGE_CONTACT_US_PHONE: '6020*',
  INSURANCE_PAGE_CONTACT_US_TITLE: 'יש לכם עוד שאלות? לפניות הציבור',
  INTERCITY: 'בין עירוני',
  URBAN: 'עירוני',
  MERGED_CONSUPTION_TYPE: 'משולב',
  FREESBE_BRANCHES: 'סניפי freesbe',
  SUBSCRIPTION_PLANS_TITLE: 'מה כולל המינוי שלי?',
  SUBSCRIPTION_LABEL_TEXT: '100%\n חשמלית',
  DEAL_FOR_36_MONTH: 'עסקה ל36 חודשים',
  ADVANCE_PAYMENT_SUBSCRIPTION: ' תשלום מקדמה ₪7,900',
  END_PAYMENT_SUBSCRIPTION: 'תשלום בסוף התקופה ₪11,900',
  FOR_MONTH: 'לחודש ',
  OR_X_FOR_MONTH: 'או {x} לחודש ',
  USED_CAR_RECOMMENDATION_TITLE: 'רכב יד שניה',
  NEW_CAR_RECOMMENDATION_TITLE: 'רכב חדש מהיבואן',
  LEASE_CAR_RECOMMENDATION_TITLE: 'ליסינג פרטי לרכב חדש',
  FOR_ALL_PACKAGES: 'לכל המסלולים',
  CHOOSE_YOU_PACKAGE: 'בחרו את המסלול שלכם',
  WHAT_MY_DEAL_INCLUDES: 'מה כולל המינוי שלי?',
  NEW: 'חדש',
  OFFER_SUMMARY: 'לסיכום ההצעה',
  REFOUND_YOUR_CAR: 'זיכוי על מכוניתך',
  WHAT_IS_YOU_COLOR: 'מה הצבע שלך?',
  CLOSE: 'סגירה',
  YES: 'כן',
  NO: 'לא',
  THREE_YEARS_WARRANTY: 'אחריות ל-3 שנים',
  RETURN_OPTION: 'אפשרות החזרה כל 12 חודשים',
  CAR_CHARGER: 'עמדת טעינה',
  ADDITIONS_PACKAGE: 'חבילת אבזור',
  MANDATORY_INSURANCE: 'ביטוח חובה ומקיף',
  NO_PRE_PAYMENT: 'ללא מקדמה',
  DEAL_DETAILS: 'פרטי עסקה',
  SUBSCRIPTION_LEGAL_INFO:
    'כפוף לתקנון. מחיר הרכב למזומן עפ"י מחירון יבואן מס 2023008 הינו 159,990 ₪ הכולל אגרת רישוי בסך של 558 ₪ ומע"מ. לבחירה, חבילת שיווק משולבת בתוכנית מימון, כמפורט בתקנון חבילת השיווק; מקדמה ע"ס 49,150 ₪, תשלום חודשי של 1,290 ₪ למשך 36 חודשים ויתרה של 89,984 ₪ בתום התקופה. המינוי החודשי אינו כולל אגרות רישוי שנתיות שתהיינה בהמשך ואינו כולל שירות תיקונים. המימון בכפוף לתנאי החברה אוטוקאש שרותי מימון בע"מ מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל. המימון מותנה באישור אוטוקאש וקבלת מסמכי האשראי והכל בכפוף לתנאי הסכם המימון שייחתם מול אוטוקאש.',
  FILTERS_RESULTS_TITLE_USED_CARS: 'תוצאות - רכבי יד שנייה למכירה',
  FILTERS_RESULTS_TITLE_NEW_CARS: 'תוצאות - רכבים חדשים למכירה',
  FILTERS_RESULTS_PRIVATE_LEASING: 'תוצאות - רכבי ליסינג פרטי',
  CLOSE_MARKETING_POPUP: 'סגור מסך פרסומי',
  GOOD_TO_KNOW: 'טוב לדעת',
  PRICE: 'מחיר',
  BEFORE_DISCOUNT: 'לפני הנחה',
  DISCOUNT: 'הנחה',
  VEHICLE_IN_CATEGORY: 'רכב זה בקטגוריית',
  ADVANTAGES_IN_CATEGORY: 'יתרונות הרכב ביחס לקטגוריית',
  TO_FULL_SPEC: 'למפרט הטכני המלא',
  FUNDING_OFFER_TITLE: 'חישוב הצעת מימון עבור הרכב',
  FUNDING_OFFER_SECTION_TITLE: 'חישוב הצעת מימון עבור הרכב',
  ANSWER_TO_CHECK_FUNDING_APPROVAL: ' 5 שאלות פשוטות וגלו אם אתם זכאים לאישור',
  CHECK_APPROVAL_LOAN: 'לבדיקות זכאות למימון',
  READ_MORE: 'קראו עוד',
  RECOMMENDATIONS_TITLE: 'מכוניות נוספות שחשבנו שתאהבו',
  CSV_TABLES_INFO: 'הסדרי נגישות בסניפי',
  CHARGING_SOLUTIONS_INFO: `חטיבת פתרונות הטעינה שלנו כיום מספקת פתרונות טעינה הן ללקוחות הקבוצה והן ללקוחות חדשים.
  אנו מספקים פתרונות טעינה למגוון צרכים – עמדות טעינה לרכב חשמלי ללקוחות פרטיים, עמדות טעינה לרכב חשמלי לקוחות עסקיים, עמדות טעינה לרכבים חשמלי לרשויות מוניציפליות וכיו'.
  אנו גם מספקים שירותי התקנת מטען והתקנת עמדת טעינה לרכב חשמלי ומכירת אביזרים לרכבים חשמליים.
  בנוסף אנו שואפים לספק לכם.ן שקט ולמזער את החששות בכל מה שקשור לטווחי הנסיעה, היכן להטעין ואם בכלל לרכוש/להשכיר רכב חשמלי.
  אנו פורסים רשת עמדות טעינה ציבוריות איטיות ומהירות, הרשת מתבססת על סניפי ונכסי הקבוצה במעל 120 אתרים ברחבי הארץ וכן על שיתופי פעולה עם הקבוצות המובילות במשק. אנו דוגלים במקצועיות ושירותיות כערך עליון.`,
  CHARGING_SOLUTIONS_TITLE: 'פתרונות טעינה עם',
  CHARGING_SOLUTIONS_EXTRA_ACCESSORIES: 'אביזרים נלווים',
  CHARGING_SOLUTIONS_INTRESTED_BUT_MORE_QUESTIONS:
    'יש לכם עוד שאלות? דברו איתנו ',
  CHARGING_SOLUTIONS_INTRESTED_BUT_MORE_QUESTIONS_INFO:
    'אם אתם מחפשים סקר להתקנת עמדות טעינה, לא בטוחים איזו עמדה מתאימה לכם או סתם רוצים לקשקש, השאירו פרטים ונחזור אליכם בהקדם.',
  WHAT_YOU_GET: 'מה מקבלים?',
  OUR_SOLUTIONS: 'הפתרונות שלנו',
  INPUT_KM_TITLE: "קילומטראז'",
  SET_YEARS_TITLE: 'שנה',
  ADDITOINAL_FILTERS: 'חיפוש מתקדם',
  USER_DETAILS_OTP_BUTTON: ' שלח שוב ',
  USER_DETAILS_OTP_TEXT: 'ההודעה לא הגיעה? ',
  CLOSE_ADDITIONAL_FILTERS: 'סגור מסננים',
  FROM_YEAR: 'משנת',
  TO_YEAR: 'עד שנת',
  FROM_YEAR_LABEL: 'משנה',
  TO_YEAR_LABEL: 'עד שנה',
  MANUFACTURE_YEAR: 'שנת ייצור',
  SORT_BY: 'מיין לפי',
  PURCHASE_PLAN: 'תוכניות רכישה',
  FINISH_LEVEL: 'רמת גימור',
  MODEL_AND_FINISH_LEVEL: 'דגם ורמת גימור',
  CAR_INFO_YEAR: 'שנה',
  CAR_INFO_TO_YEAR: 'לשנה',
  CURRENT_KM: 'קילומטראז׳',
  CAR_HAND: 'יד',
  CHOOSE_CAR_HAND: 'בחרו יד',
  GIR: 'תיבת הילוכים',
  CAR_INFO_ENGINE_VOLUME: 'נפח מנוע',
  WHAT_IS_ZERO_KM_PRICE_TITLE: 'מה זה רכב 0 ק״מ?',
  WHAT_IS_ZERO_KM_PRICE_DESC:
    'רכב חדש מהניילונים, אבל במחיר של יד שנייה. זו ההזדמנות שלך ליהנות ממגוון המותגים שלנו ולשלם... פחות. ',
  ORIGIN_CAR_OWNERSHIP: 'מקוריות הרכב',
  INFORMATION_OF_THE_CAR: 'מידע על הרכב',
  // BUYING_PRICE: 'מחיר קניה',
  // FUNDING_PRICE: 'מחיר מימון',
  RIGHT_NOW_IM_IN: 'כרגע אני בסניף',
  BUT_I_CAN_BE_MOVED: 'אבל יכולים להעביר אותי לסניף שקרוב אליך!',
  EXPOSE_ONLINE_PRICE: 'חשפו מחיר אונליין',
  WHAT_IS_ONLINE_PRICE_TITLE: 'מה זה מחיר אונליין?',
  WHAT_IS_ONLINE_PRICE_DESC:
    'מחיר מוזל לגולשי האתר. כדי לקבל הצעת מחיר אונליין על דגם מסוים, צריך רק להשאיר פרטים על הרכב ונציג יחזור אליכם בהקדם',
  WHAT_IS_ONLINE_PRICE_DESC2:
    'מחיר מוזל לרכב רק לימים הקרובים. לחצו לחשיפת מחיר אונליין, מלאו את הפרטים, וחישפו את המחיר המיוחד!',
  SHIRYUN_ONLINE_PRICE: 'המשיכו לשריון ',
  PRICE_BEFORE_EXPOSE: 'המשיכו אל המכונית כדי לחשוף את המחיר המיוחד באתר',
  EXPOSED_ON_DATE: 'חשפת את מחיר האונליין ב',
  OTP_SEND_YOU_CODE: 'שלחנו אליך קוד למספר שמסתיים ב-',
  CAR_PRICE: 'מחיר הרכב',
  PRICE_STARTING_FROM: 'החל מ- ',
  PRICE_BANNER_ZERO: 'מחירון',
  MONTHLY_PRICE: 'מחיר חודשי',
  BEFORE_ZERO_KM_DISCOUNT: 'לפני הנחת 0 ק"מ',
  CAR_PRICE_TOTAL: 'מחיר הרכב כולל תוספות',
  TO_RENTAl_CAR: 'להשכרת רכב',
  HOME_PAGE_QUICK_SEARCH_INTRUSTING_U: 'חיפושים מובילים:',
  HOME_PAGE_RENTAL_BANNER_HEADER: 'מחפשים מכונית זמנית להשכרה >> ',
  HOME_PAGE_RENTAL_BANNER_HEADER_LABEL: 'מחפשים מכונית זמנית להשכרה',
  HOME_PAGE_SEARCH_RENTAL_TITLE: 'מחפשים מכונית זמנית להשכרה',
  SEARCH_RENTAL_BRANCHES_TITLE: 'מיקום איסוף והחזרה',
  SEARCH_RENTAL_PICKUP_BRANCH_PLACEHOLDER: 'חפשו נק׳ איסוף',
  SEARCH_RENTAL_RETURN_BRANCH_PLACEHOLDER: 'חפשו נק׳ החזרה',
  SEARCH_RENTAL_RETURN_SAME_AS_PICKUP: 'החזרה לאותו מקום',
  SEARCH_RENTAL_PICKUP_BRANCHES_POPUP_TITLE: 'מאיפה תרצו לקחת?',
  SEARCH_RENTAL_RETURN_BRANCHES_POPUP_TITLE: 'מאיפה תרצו לאסוף?',
  SEARCH_RENTAL_BTN_TEXT: 'חפשו',
  SEARCH_RENTAL_AGE_TEXT: 'אני מעל גיל',
  MAYBE_YOU_LIKE_SUGGESTIONS: 'אולי תאהבו גם',
  WANT_SEE_MORE: 'רוצים לראות עוד?',
  SECOND_HAND: 'יד שנייה',
  CATEGORIES_CAROUSEL_TITLE: 'איזו קטגוריה להראות לכם?',
  HP_VIDEO_SECTION_BTN_TEXT: 'מי אנחנו?',
  FOR_EXPOSE_PRICE: 'לחשיפת מחיר האונליין',
  PRESS_HERE: 'לחץ כאן',
  WHAT_IS_YOU_CLOSET_BRANCH: 'מה הסניף הקרוב אליך?',
  SEARCH_CHOOSE_CAR_TYPE: 'בחרו',
  ACCURATE_SEARCH_CHOOSE_MODELS_HEADER: 'אילו דגמים מתאימים לך?',
  ACCURATE_SEARCH_BUDGET: 'בחרו תקציב לרכב',
  ACCURATE_SEARCH_YEAR: 'בחרו שנת ייצור',
  ACCURATE_SEARCH_CHOOSE_YEAR_BUDGET: 'הגדירו שנת ייצור ותקציב שנוח לכם',
  ACCURATE_SEARCH_CHOOSE_CATEGORIES_HEADER:
    'בחרו את הקטגוריה שמתאימה לכם, אפשר גם יותר מאחת',
  ACCURATE_SEARCH_CHOOSE_BUDGET: 'בחרו תקציב (אופציונלי)',
  ACCURATE_SEARCH_PAYMENT: 'תשלום',
  ACCURATE_SEARCH_CHOOSE_CATEGORY: 'אפשר לבחור ערך אחד או יותר',
  ACCURATE_SEARCH_CHOOSE_BRAND_AND_MODEL:
    'בחרו ממגוון היצרנים והדגמים (ערך אחד או יותר)',
  ACCURATE_SEARCH_OR: 'או',
  ACCURATE_SEARCH_OUR_POPULAR: 'או מהפופולארים שלנו',
  GENERAL_SEARCH_CHOOSE_HIGHLIGTHS_TITLE: 'אפשר לבחור ערך אחד או יותר',
  GENERAL_SEARCH_CHOOSE_BODY_TYPE_STEP_TITLE: 'בחרו את צורת הרכב',
  GENERAL_SEARCH_CHOOSE_BODY_TYPE_TITLE:
    'אפשר לבחור ערך אחד או יותר. אפשר גם לדלג',
  GENERAL_SEARCH_TIP_TITLE: 'לא בטוחים?',
  GENERAL_SEARCH_TIP_SUBTITLE: 'תמיד תוכלו לדלג על השאלה',
  BUDGET_SEARCH_DISCLAIMER: `מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר  54715
  . אי עמידה  בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.`,
  BUDGET_SEARCH_FIRST_STEP_BUTTON_TEXT: 'תראו לי רכבים',
  BUDGET_SEARCH_FIRST_STEP_TEXT1:
    'ספרו לנו על התקציב שלכם כדי שנוכל להציע את המימון הכי מותאם, ובתנאים',
  BUDGET_SEARCH_FIRST_STEP_TEXT2: ' שנוחים לך',
  BUDGET_SEARCH_FIRST_STEP_TEXT3_DESKTOP:
    'סוג המכונית ישפיע על הריביות והחישוב שלנו עבור ההלוואה שלך ',
  BUDGET_SEARCH_TITLE: 'רכב בתנאים שלכם',
  BUDGET_SEARCH_CAR_TYPE_TITLE: 'על איזה רכב חשבת?',
  BUDGET_SEARCH_ADVANCED_TITLE: 'המקדמה שלי',
  BUDGET_SEARCH_ADVANCED_TITLE_FIXED: 'המקדמה שלי (מקדמה קבועה)',
  BUDGET_SEARCH_MONTHLY_PAY_TITLE: 'ההחזר החודשי שמתאים לי',
  BUDGET_SEARCH_PRICE_PLACEHOLDER: 'הזינו סכום',
  BUDGET_SEARCH_TITLE_STEP2: 'המכונית הבאה כבר פה',
  BUDGET_SEARCH_YOUR_BUDGET_TITLE1: 'התקציב ',
  BUDGET_SEARCH_YOUR_BUDGET_TITLE2: 'שלך',
  BUDGET_SEARCH_YOUR_BUDGET_TITLE3: ' לרכישה',
  BUDGET_SEARCH_YOUR_BUDGET: ' בתקציב שלך',
  BUDGET_SEARCH_INFO:
    'אפשרות מימון מתוך מגוון האפשרויות המותאמות אישית. אפשרות למימון לרכישת הרכב בתשלום חודשי קבוע (צמוד למדד המחירים לצרכן) ',
  BUDGET_SEARCH_INFO_1: `המחשבון הינו כלי עזר בלבד להערכת תקציב הרכישה על בסיס הנתונים שהוקלדו על ידך ואינו מהווה הצעה למימון.
  מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.`,
  BUDGET_SEARCH_ADVENCED_OF: 'מקדמה של',
  BUDGET_SEARCH_END_OF_PERIOD: 'סוף תקופה',
  BUDGET_SEARCH_60_MONTHS: '60 חודשים',
  BUDGET_SEARCH_MONTHS: 'חודשים',
  BUDGET_SEARCH_PAY_FOR_MONTH: 'לחודש',
  BUDGET_SEARCH_PAY_ALL_CARS_IN_BUDGET: 'לכל הרכבים בתקציב שלי',
  SHOW_CAR_WITH_IMAGE_ONLY: 'הצג רכבים רק עם תמונה',
  IMAGE: 'תמונה',
  CARS_WITH_IMAGES: 'רכבים עם תמונה',
  IN_CATEGORY: 'בקטגוריית',
  OUR_BRANCHES: 'הסניפים שלנו',
  BRANCHES: 'הסניפים שלנו',
  SERVICE_TYPE: 'סוג שירות',
  SUNDAY_TO_FRIDAY: 'ראשון - חמישי:',
  FRIDAY: 'שישי וערבי חג:',
  NAVIGATE_TO_BRANCH: 'נווטו לסניף',
  BRANCH: 'סניף',
  CHOOSE_BRANCH: 'בחרו סניף',
  CHOOSE_BRANCH_CLOSE: 'בחרו את הסניף הקרוב אליכם',
  FILTER_BY: 'סינון לפי:',
  BRANCH_FOUND_X_MANY: 'נמצאו {num} סניפים',
  BRANCH_FOUND_X_SINGLE: 'נמצא סניף 1',
  BLOG_MAIN_PAGE_TITLE: 'הבלוג של freesbe',
  FOR_MORE_ARTICLES: 'לכתבות נוספות',
  MORE_ARTICLES: 'כתבות נוספות בבלוג',
  ALL_TAG: 'הכל',
  MODEL_DET: 'אודות הדגם',
  FOR_CREATE_CAR: 'להתאמת רכב',
  FOR_BUY_CAR: 'לרכישה אונליין',
  FOR_CREATE_CAR_SALE: 'להתאמת רכב לקניה',
  INTERESTED_IN_FUNDING: 'אני מעוניין במימון לרכב',
  JUMP_TO_SEE_THE_CAR:
    'קפצו להתרשם מהרכב, לקבל את כל הפרטים ולחזור עם ראש שקט וחיוך',
  WANT_TO_COME_AND_SEE: 'רוצים לבוא לראות את הרכב?',
  FOR_CREATE_CAR_FUNDING: 'להתאמת רכב במימון',
  SPECIALLY_FOR_YOU: 'התאימו את הרכב בקלות',
  USED_TO_NEW: 'איך הופכים משומש לכמו חדש?',
  LETS_MEET: 'בואו ניפגש',
  RESERVE_DEAL: 'לשריון העסקה',
  ORDER_DEAL: 'להזמנת רכב',
  SCHEDULE_TEST_DRIVE: 'תאמו נסיעת מבחן',
  SCHEDULE_TEST_DRIVE2: 'לנסיעת מבחן',
  VIEW_OUR_MODELS: 'צפו בדגמים שלנו',
  PLEASE_TRY_AGAIN: '.הקוד לא תקין, אנא נסו שנית',
  WHAT_NEXT: 'מה הלאה?',
  OUR_LOCACATIONS_OPENING_HOURS: 'שעות פתיחה',
  CONTACT_US_NEED_HELP_TITLE: 'צריכים עזרה ?',
  CONTACT_US_NEED_FAST_HELP: '?צריכים עזרה מהירה',
  CONTACT_US_NEED_HELP_TITLE_SPAN: 'לחצו כאן ',
  SELL_CAR_HEADER: 'רוצים למכור את המכונית?',
  SELL_CAR_SUBTITLE:
    'ספרו לנו קצת על המכונית ואנחנו ב-freesbe נחזור אליכם עם הצעת מחיר',
  SELL_CAR_ENTER_CAR_NUM: 'הכניסו את מספר הרכב',
  SELL_CAR_ENTER_CAR_NUM_PLACEHOLDER: 'מספר רכב',
  SELL_CAR_SUMMARY_THANK_YOU: 'תודה',
  SELL_CAR_SUMMARY_STAY_IN_TOUCH: 'צרו קשר',
  SELL_CAR_SUMMARY_GOT_YOUR_MESSAGE: 'קבלנו את הבקשה שלך',
  SELL_CAR_SUMMARY_STAY_UPDATED: 'זמינים לכל שינוי או בקשה גם באמצעים הבאים',
  SELL_CAR_SUMMARY_FIND_YOUR_CAR: 'עכשיו אפשר למצוא את המכונית הבאה שלכם ',
  SELL_CAR_SUMMARY_WHAT_HAPPENS_NOW: 'מה קורה עכשיו?',
  SELL_CAR_SUMMARY_REPRESENTIVE_CALLBACK:
    'אחד מנציגנו יחזור אליך בהקדם עם הצעה אישית',
  SELL_CAR_CONTACT_US_ERROR_POPUP_BUTTON_LABEL: 'לחזרה',
  HOW_ITS_WORK: 'איך זה עובד?',
  NEED_HELP: 'צריכים עוד עזרה? דברו איתנו',
  OWNER: 'בעלות',
  OWNER_TYPE: 'סוג בעלות',
  INSERT_AMOUNT: 'הזינו מספר',
  NOT_YOUR_CAR: 'לא הרכב שלך?',
  LICENSE_NUMBER: 'מספר רישוי',
  UPLOAD_IMAGES_ADD_IMAGES: 'הוסיפו תמונות ( ניתן עד 5 קבצים)',
  UPLOAD_IMAGES_ADD_IMAGES_SHORT: 'הוסיפו תמונות',
  UPLOAD_IMAGES_LIMIT: 'על כל תמונה להיות עד גודל של 5MB',
  UPLOAD_IMAGES__SIZE_LIMIT_ERROR: 'גודל התמונה עבר את 5Mb',
  UPLOAD_IMAGES_LIMIT_ERROR: 'ניתן לעלות עד 5 תמונות',
  UPLOAD_IMAGES_SAME_IMAGE_ERROR: 'התמונה שבחרת קיימתֿ',
  ADD_PERSONAL_DETAILS: 'הזינו פרטים אישיים לחזרה',
  FIND_CAR_BY_LICENSE_NUMBER_ERROR:
    'המכונית לא נמצאה. ודאו את הפרטים ונסו שנית',
  CHOOSE_LOCATION_FOR_SELL_APPOINTMENT: 'בחרו סניף',
  IS_CAR_ACCIDENT: 'האם הרכב עבר תאונה?',
  CAR_SELL_CONTINUE: 'המשיכו',
  EMAIL_PLACEHOLDER: 'example@email.com',
  PHONE_PLACEHOLDER: '05xxxxxxxx',
  SELL_CAR_LEAD_NOTES:
    'רכב למכירה: {carMake} {carModel} | רישוי: {licenseNumber} | בעלות {owner} | {km} ק״מ | יד {hand} | תאונה: {isCarAccident} | {imagesUrls}',
  VOLUNTEERING_IS_FULL: 'ההתנדבות יצאה לפועל',
  REGISTER: 'להרשמה',
  VOLUNTEER_SIGNUP_TITLE: 'הרשמו אל ההתנדבות',
  VOLUNTEER_SIGNUP_BTN: 'להרשמה',
  VOLUNTEER_SIGNUP_TNX: 'תודה על הרשמתך!',
  VOLUNTEER_SIGNUP_CONTACT_BACK: 'ניצור איתך קשר בהקדם',
  VOLUNTEER_SUCCESS_TITLE1: 'נרשמת להתנדבות,',
  VOLUNTEER_SUCCESS_TITLE2: 'ביחד ננצח!',
  VOLUNTEER_SUCCESS_DESCRIPTION:
    'תודה על ההרשמה לעזרה. פרטייך נרשמו וניצור איתך קשר בשעות הקרובות',
  NOTES: 'הערות',
  IS_WITH_COMPONY_CAR: 'האם קיים רכב רכב?',
  HOURS: 'שעות',
  SEC: 'שניות',
  MINUTES: 'דקות',
  DAYS: 'ימים',
  IS_AVAILABLE_AT_SATURDAY: 'האם ישנה זמינות בשבת?',
  DEPARTMENT: 'מחלקה',
  COMMUNITY_ACTIVITY_CARDS_TITLE: 'פעילויות אחרונות',
  COMMUNITY_ACTIVITY_CARDS_MORE: 'לפעילויות נוספות',
  COMMUNITY_ACTIVITY_CARD_BUTTON: 'עוד על העמותה',
  COMMUNITY_ACTIVITY_PAGE: 'השקעות חברתיות',
  COMMUNITY_PAGE_TITLE: 'freesbe בקהילה',
  LOCATION_PAGE_INFO_BANNER: `לאור המצב הבטחוני בהתאם להנחיות פיקוד העורף אנו פועלים במסגרת
  מצומצמת, אנא התעדכנו בשעות פתיחת הסניפים במספר `,
  BRANCHES_NOT_FOUND: 'לצערינו לא מצאנו סניפים המתאימים לחיפוש...',
  CHOOSE_BRAND: 'בחרו מותג',
  NOT_FOUND: `אופס הדף שחפשתם ברח עם רכב חדש \n
  נשמח לעזור לכם להשיג אותו `,
  TO_NEW_CAR: 'לרכבים חדשים',
  TO_USED_CAR: 'לרכבי יד שנייה',
  TO_LEASE_CAR: 'לרכבי ליסינג',
  TO_HOME_PAGE_BTN: 'לחזרה לעמוד הבית',
  LEASING_WITH: 'ליסינג עסקי עם',
  LEASING_WITH_DESCRIPTION:
    'freesbe פועלת בישראל משנת 1933 בעולמות הרכב, ומפעילה כ-100 סניפים ברחבי הארץ. freesbe היא היבואנית הרשמית של המותגים: רנו, ניסן, דאציה, אינפיניטי, צרי, XPENG ו-EVEASY. אנחנו ב-freesbe מעניקים מגוון שירותי רכב ללקוחות עסקיים קטנים כגדולים גם בסניפים וגם באתר האינטרנט שלנו. לרבות, מכירה והשכרת רכבים, שירותי ביטוח, שירותי מימון, מכירה ושיווק של כלי צמ"ה, פתרונות טעינה לרכבים חשמליים, ליסינג פרטי וכמובן ליסינג תפעולי לכל העסקים. מערך השירות והתפעול שלנו צומח ומתחדש כל הזמן במטרה לתת ללקוחותינו את השירות הטוב ביותר. הקשר האישי עם הלקוח, השירות המצוין והמקצועיות של כל אנשי הצוות שלנו הופכים אותנו לחברת הליסינג המושלמת עבור העסק שלך.',
  LEASING_MORE_DETAILS: 'להשארת פרטים',
  LEASING_OUR_CLIENTS_HEADER:
    'פריסבי מספקת שירותי ליסינג לחברות המובילות במשק הישראלי, וביניהן:',
  LEASING_QA_ELEMENTS_HEADLINE: 'קצת שאלות ותשובות, בשביל זה אנחנו פה',
  LEASING_QA_WHAT_IS_BUSINESS_LEASING: 'מהו ליסינג עסקי?',
  LEASING_QA_WHAT_IS_BUSINESS_LEASING_A:
    'ליסינג עסקי היא עסקת ליסינג בין מעסיק לבין חברת הליסינג, המספקת הטבות המותאמות לעסק ולעובדיו. סל השירותים כולל גם ביטוח, טיפולים, תיקונים ועוד, ואנשי השירות שלנו ילוו את העסקה מתחילתה ועד סופה ויספקו ייעוץ מקצועי ואמין בכל שלבי הדרך.',
  LEASING_QA_BUSINESS_OR_OPERATIVE_LEASING:
    'מה ההבדל בין ליסינג עסקי לליסינג תפעולי?',
  LEASING_QA_BUSINESS_OR_OPERATIVE_LEASING_A:
    'ליסינג תפעולי הוא למעשה שמו השני של הליסינג העסקי. הליסינג התפעולי הוא סוג של עסקת חכירה הכוללת בתוכה גם את כל האספקטים של אחזקת הרכב, והיא מוצעת לכלל הלקוחות בשוק, אך הוא נודע כסוג העסקה המועדף על עסקים המעוניינים להאציל את ההתעסקות ברכב לחברת הרכב, ולהתמקד בניהול העסק שלהם ללא הפרעות.',
  LEASING_QA_WHO_BENEFITS_FROM_LEASING: 'למי משתלם הליסינג העסקי?',
  LEASING_QA_WHO_BENEFITS_FROM_LEASING_A:
    'הליסינג העסקי אמנם פרץ לתודעה הישראלית כעסקה המתאימה לעסקים גדולים, אך התשובה היא שליסינג עסקי יכול להשתלם לעסקים בכל גודל. ליסינג עסקי הוא סוג עסקה גמיש מאוד, וכל לקוח, מעסק קטן או אפילו מעצמאי הזקוק לרכב אחד ועד חברת ענק הזקוקה לצי רכבים, יכול למצוא עסקה משתלמת ונוחה.',
  LEASING_QA_BENEFITS: 'מה היתרונות של ליסינג עסקי ב- freesbe?',
  LEASING_QA_BENEFITS_A:
    'חברת freesbe היא קבוצת הרכב הגדולה בשוק הישראלי. לכן, באפשרותה לספק ללקוחותיה מבחר גדול של רכבים, הכולל רכבים מסחריים, רכבים חשמליים (לרבות פתרונות טעינה), ובעיקר שירות וליווי אישי בכל שלבי העסקה, ומחויבות בלתי מתפשרת לאמינות ושקיפות. נשמח להתאים את העסקה לצרכים הספציפיים של העסק תוך כדי מתן שירות אמין, שקוף ומקצועי.',
  HOME_PAGE_BANNERS_GALLERY: 'חם ב-freesbe',
  HP_SEARCH_MOBILE_CONTACT_US_BTN: 'בואו נדבר',
  HP_SEARCH_MOBILE_CONTACT_US_TITLE: 'צריכים עזרה?',
  PRIVATE_LEASING: 'ליסינג פרטי',
  HOMEPAGE_NEW_CAR_LABEL: 'רכב חדש',
  TO_ALL_CARS: 'לכל המכוניות',
  SEARCH_BY_FUNDING_TITLE1: 'גלו את אפשרויות המימון שלנו',
  // SEARCH_BY_FUNDING_TITLE1: 'אפשרויות מימון?',
  SEARCH_BY_FUNDING_TITLE2: 'ב-freesbe זה קל',
  SEARCH_BY_FUNDING_DOWN_PAYMENT: 'סכום המקדמה שמתאים לך',
  SEARCH_BY_FUNDING_MONTHLY_RETURN: 'ההחזר החודשי שנוח לך',
  FUNDING_CALC_TITLE: 'מחשבון מימון לרכב יד שנייה',
  FUNFING_CALC_DESCRIPTION:
    'מחשבון המימון שלנו יעזור לכם בתכנון או בהתאמת ההחזר החודשי שלכם. בחרו את סכום המקדמה וההחזר החודשי שמתאים לכם, ואנחנו נציג לכם רכבים בתקציב.',
  FUNDING_INFO1: 'אפשרות מימון מתוך מגוון האפשרויות המותאמות אישית.',
  FUNDING_INFO2:
    'אפשרות למימון לרכישת הרכב בתשלום חודשי קבוע (צמוד למדד המחירים לצרכן)',
  FUNDING_INFO_MOBILE:
    'אפשרות למימון הרכב בתשלום חודשי קבוע (צמוד למדד המחירים לצרכן)',
  FUNDING_SEARCH_BUTTON: 'מצאו לי מכונית!',
  BANNER_GALLERY_NEW_CAR_LOBBY: 'הדילים הכי שווים',
  BANNER_GALLERY_PRIVATE_LEASING_LOBBY: 'חם בליסינג',
  BANNER_GALLERY_USED_CAR_LOBBY: 'חם ביד שנייה',
  SCALE_FROM: 'עד',
  CONTACT_US_LEAD_BANNER_TITLE: 'תנו לנו לחזור אליכם',
  CONTACT_US_LEAD_BANNER_SUBTITLE:
    'תשאירו לנו את כל הפטפוטים! ספרו לנו מי אתם ואנחנו כבר ניצור איתכם קשר',
  NEED_HELP_BANNER_CONTACT_BTN: 'דברו איתנו',
  CAR_TAB_ABOUT_CAR: 'על הרכב',
  CAR_TAB_CONFIGURE_CAR: 'התאמת הרכב',
  CAR_TAB_ABOUT_MODEL: 'על הדגם',
  CAR_TAB_SET_MEETING: 'קבעו פגישה',
  CAR_TAB_FUNDING_OPTIONS: 'מימון',
  CAR_TAB_SPEC: 'מפרט טכני',
  CAR_TAB_RECOMMENDED_CARS: 'רכבים דומים',
  FOMO_LISTING: 'מעורר עניין',
  FOMO_CAR_PAGE: 'מעורר עניין ב-7 הימים האחרונים',
  FOMO_LEASE_PAGE: 'מעורר עניין בימים האחרונים',
  FOMO_USED_CAR_PAGE: 'מעורר עניין ב-3 הימים האחרונים',

  FOMO_CAR_PAGE_30: 'מעורר עניין בחודש האחרון',
  ERROR_CREATE_CUSTOMER_TITLE: 'נחזור אליכם',
  ERROR_CREATE_CUSTOMER_DESCRIPTION:
    'נתקלנו בשגיאה כשניסינו להתקדם. אל תדאגו, שמרנו את הפרטים ונחזור אליכם.',
  ERROR_CREATE_CUSTOMER_BTN_LABEL: 'לסגירה',
  ERROR_TRANZILA_DECLAIM:
    'לצערנו, קיבלנו סירוב מחברת האשראי. תוכלו לנסות כרטיס שונה או להמתין שנחזור אליכם להשלמת העסקה.',
  ERROR_TRANZILA_CALL_TO_CREDIT_COMPANY:
    'להשלמת העסקה יש צורך באישור מחברת האשראי. נחזור אליכם בהקדם עם יותר פרטים.',
  ERROR_TRANZILA_ERROR_CARD_NUMBER:
    'מספר הכרטיס שהוזן שגוי. קיבלנו אתתוכלו לנסות שנית או להמתין שנחזור אליכם להשלמת העסקה.',
  ERROR_TRANZILA_ERROR_CARD_TYPE:
    'לצערנו, כרטיס זה אינו נתמך. תוכלו לנסות כרטיס שונה או להמתין שנחזור אליכם להשלמת העסקה.',
  ERROR_TRANZILA_GENERAL_ERROR:
    'לצערנו, לא הצלחנו לבצע את העסקה מול חברת האשראי. נחזור אליכם בקרוב להשלמת העסקה.',
  // Loader
  LOADING_DEALS: '"הדילים בדרך, שווה לחכות"',
  // LOADING_DEALS: '"רק רגע... הדילים הטובים ביותר בדרך!"',
  TEST_DRIVE_USER_TITLE: 'איזה כיף! מתאמים נסיעת מבחן',
  TEST_DRIVE_USER_SUBTITLE:
    'כדי שנוכל לתאם לכם נסיעת מבחן נצטרך שתמלאו כמה פרטים',
  TEST_DRIVE_SHOWROOM_TITLE: 'בחרו אולם תצוגה',
  TEST_DRIVE_SHOWROOM_SUBTITLE:
    'כאן אנחנו מציגים את הדגמים הזמינים בכל אולם תצוגה. בחרו מה שמתאים לכם',
  TEST_DRIVE_TIME_TITLE: 'בחרו יום ושעה',
  TEST_DRIVE_TIME_SUBTITLE:
    'כאן מופיעים חלונות הזמן הזמינים בארבעת הימים הקרובים. בחרו את היום והשעה שנוחים לכם',
  SELECT_SHOWROOM_BTN: 'לבחירת אולם תצוגה',
  FINISH_TEST_DRIVE_TITLE: 'נתראה בקרוב!',
  FINISH_TEST_DRIVE_DESCRIPTION:
    'שמרנו את התאריך. הנציגים שלנו יחכו לכם יחד עם הרכב ביום ובשעה שקבעתם. נדאג לשלוח לכם תזכורת כדי שלא תפספסו',
  FINISH_TEST_DRIVE_WHAT_HAPPEN_NEXT: 'מה קורה עכשיו?',
  FINISH_TEST_DRIVE_INSTRUCTION_ONE:
    'ניפגש ביום שקבעתם - נשלח תזכורת לפני (לא לדאוג)',
  FINISH_TEST_DRIVE_INSTRUCTION_TWO: 'הקפה והחנייה עלינו',
  FINISH_TEST_DRIVE_INSTRUCTION_THREE: 'נסגור את כל הפרטים פנים מול פנים',
  FINISH_TEST_DRIVE_ERROR_SLOTS_TITLE: 'אנחנו נחזור אליכם',
  FINISH_TEST_DRIVE_ERROR_SLOTS_DESC:
    'מצטערים, נראה שאין חלונות זמן בארבעת הימים הקרובים. קיבלנו את הפרטים ונחזור אליכם לתיאום הנסיעה',
  FINISH_TEST_DRIVE_ERROR_BTN: 'סגירה',
  FINISH_TEST_DRIVE_ERROR_TITLE: 'נתקלנו בבעיה',
  FINISH_TEST_DRIVE_ERROR_DESC:
    'מצטערים, משהו השתבש לנו בדרך. שמרנו את כל הפרטים ואחד מנציגי השירות שלנו יצרו קשר בקרוב כדי להשלים את התהליך',
  BACK_TO_CAR_PAGE: 'חזרו לעמוד רכב',
  SELECT_MODEL: 'בחרו דגם (אופציונלי)',
  SELECT_SHOWROOM: 'בחרו  אולם תצוגה',
  SELECT_TIME_SLOTS: 'לבחירת יום ושעה',
  SELECT_TIME: 'בחרו שעה',
  SELECT_TEST_DRIVE_DATE: 'בחרו תאריך לנסיעת המבחן',
  BOOK_TEST_DRIVE: 'לתיאום נסיעת מבחן',
  // Car fleet
  CAR_FLEET: 'ציי רכב',
  CAR_FLEET_RESULTS_TITLE: 'מגוון רכבי freesbe לציי רכב',
  CAR_FLEET_RESULTS_BRAND_TITLE: 'מגוון רכבי {brand} מבית freesbe',
  CAR_FLEET_SUBTITLE: 'רכבי freesbe',
  CAR_FLEET_SUBTITLE_BRAND: 'רכבי {brand}',
  CAR_FLEET_MODELS: '{num} דגמים',
  CAR_FLEET_MODEL: '{num} דגם',
  CAR_FLEET_CARD_PRICE_LIST: 'מחיר מחירון',
  CAR_FLEET_CARD_LICENSE_FEE: 'אגרת רישוי',
  CAR_FLEET_CARD_USE_VALUE: 'שווי שימוש',
  CAR_FLEET_CARD_FREESBE: 'למידע נוסף באתר freesbe',
  CAR_FLEET_OPERATION_VIDEO: 'לסרטוני תפעול',
  CAR_FLEET_BANNER_DESC: 'רוצים להרגיש את הרכב על הכביש?',
  COMPARE: 'השווה',
  COMPARE_IN: 'בהשוואה',
  COMPARE_CLOSE: 'סגרו',
  COMPARE_REMOVE_ALL: 'הסירו הכל',
  COMPARE_ADD_AND_COMPARE: 'הוסף להשוואה',
  COMPARE_CARS_BUTTON: 'השוו רכבים',
  COMPARE_CARS_BUTTON_TEXT: 'חפשו רכבי יד שניה',
  COMPARE_PAGE_TITLE: 'השוואת רכבי יד שניה',
  COMPARE_EMPTY_DESCRIPTION: `השוו בקלות בין רכבי יד שניה
  כדי להחליט עם איזה רכבים להתקדם`,
  COMPARE_PAGE_SHARE_TITLE: 'היי רציתי להתלבט איתך על כמה מכוניות',
  COMPARE_PAGE_SHARE_PARAGRAPH: 'באתר פריסיבי',
  COMPARE_HIGHLIGHT_LEADING_PARAMS: 'הדגשת הפרמטרים המנצחים',
  COMPARE_HIDE_SIMILAR_PARAMS: 'הסתרת פרמטרים זהים',
  COMPARE_ADD_VEHICLE_BUTTON: 'הוסיפו רכב',
  COMPARE_LIMIT:
    'ניתן להשוות עד 4 רכבים. כדי להוסיף רכב נוסף להשוואה, יש להסיר רכב קיים.',
  COMPARE_BUTTON_POPUP_TITLE: 'ריק פה... בואו נוסיף רכבים!',
  COMPARE_BUTTON_SUBTITLE_POPUP_PART_1: 'לחצו',
  COMPARE_BUTTON_SUBTITLE_POPUP_PART_2: 'והוסיפו עד 4 רכבים להשוואה',
  COMPARE_BUTTON_ADD_POPUP_TITLE: 'הרכב נוסף להשוואה',

  SFB_P1_TITLE: 'איזה רכב אתם מחפשים?',
  SFB_P1_PARA: 'אצלנו ב freesbe יש הכל, רק תבחרו',
  SFB_P1_NEW_CAR_TITLE: 'רכב חדש',
  SFB_P1_NEW_CAR_PARA:
    'מבחר רכבים חדשים, תנאי תשלום גמישים ואפשרות החזרה אם התחרטתם',
  SFB_P1_USED_CAR_TITLE: 'יד שניה',
  SFB_P1_USED_CAR_PARA: 'רכבי יד שניה שנבדקו בקפידה, עם אחריות ואפשרות החזרה',
  SFB_P1_LEASE_CAR_TITLE: 'ליסינג פרטי',
  SFB_P1_LEASE_CAR_PARA:
    'חדש מהניילונים בתשלומים נוחים, עם טיפולים ובלי דאגות ירידת ערך',
  SFB_DECIDE: 'מתלבטים?',
  SFB_TALK_TO_US: 'דברו עם המומחים שלנו',
  SFB_P2_TITLE: 'איך תרצו לשלם?',
  SFB_P2_PARA: 'התאימו מקדמה והחזר חודשי',
  SFB_P2_PREPAY_DECLIMER: 'ניתן עד 20% משווי הרכב',
  SFB_P3_TITLE: 'הרכב הבא כבר פה!',
  SFB_P3_TITLE_V2: 'התקציב שלכם',
  SFB_P3_PARA: 'מצאו רכבים בתקציב שלכם',
  SFB_P3_POPULAR: 'הצעות פופלאריות',
  SFB_P3_DECLIMER:
    'המחשבון הינו כלי עזר בלבד להערכת תקציב הרכישה על בסיס הנתונים שהוקלדו על ידך ואינו מהווה הצעה למימון. מתן ההלוואה בפועל הנו בכפוף לאימות הנתונים וקבלת מסמכים נדרשים וכן אישור הגוף המממן, אוטוקאש שרותי מימון בע"מ ותנאיו מקבוצת פריסבי בעלת רישיון למתן אשראי מספר 54715. אי עמידה בפירעון ההלוואה או החזר האשראי עלול לגרור חיוב בריבית פיגורים והליכי הוצאה לפועל.',
  SFB_CATEGORY_ALL: 'כל הרכבים בתקציב',
  SFB_CATEGORY_ZERO: 'רכבי 0 ק״מ',
  SFB_CATEGORY_ELECTRIC: 'חשמליים והיברידיים',
  SFB_CATEGORY_SUV: 'קרוסאוברים',
  SFB_CATEGORY_SMALL: 'קטנות',
  SFB_CATEGORY_FAMILY: 'משפחתיות',
  SFB_CATEGORY_SEARCH: 'חפשו בתקציב שלכם',
};

export const ARIA_LABELS_RESOURCES = {
  SFB_SEARCH_FOR_CATEGORIES: 'חפשו {category} בתקציב שלכם',
  DROPDOWN_SELECTED: 'נבחרו',
  CLEAN_FILTER: 'מחק סינון',
  FILTERS: 'סינונים',
  SELECT_YEAR_OF_MANUFACTURE: 'ייצור',
  PRICE_BAR_BETWEEN: 'בין',
  PRICE_BAR_SELECTED_PRICE: 'הסכום הנבחר הוא',
  SELECT_PICKUP_BRANCH: 'חפשו נקודת איסוף',
  SELECT_RETURN_BRANCH: 'חפשו נקודת החזרה',
  SCALE_FROM: 'עד',
  TECH_SPEC_PDF_DOWNLOAD: 'להורדת המפרט כקובץ PDF',
  TECH_SPEC_OPEN: 'פתח מפרט עבו',
  POPUP_WINDOW_OPEN: 'נפתח מסך',
  POPUP_WINDOW_CLOSE: 'סגירה',
  RESULTS_PAGE: 'דף תוצאות מכוניות',
  CAR_CARD_TOOLTIP_MORE_INFO: 'מידע נוסף על',
  NEW_CARS: 'חדשות',
  LEASE_CARS: 'ליסינג פרטי',
  USED_CARS: 'יד שנייה',
  USED_CARS_1: 'רכב יד שנייה',
  LEASE: 'ליסינג פרטי',
  BACK_TO_RESULTS_PAGE: 'חזרה לדף תוצאות',
  FUNDING_CALCULATOR_TOOLTIP: 'עזרה אודות מחשבון',
  CHOSEN: 'נבחר',
  CAR_DETAILS: 'פרטים נוספים על הרכב',

  LOADING_SCHEDULE_SLOTS: 'טוען שעות וימים פנויים',
  CHOOSING_MEETING_LOCATION_AND_TIME: 'בחירת אולם וזמני הגעה',
  FINISH_SCHEDULE_MEETING: 'קבענו! נפגש ב',
  FUNDING_APPROVAL_FLOW: 'בדיקת זכאות מימון',
  FUNDING_FLOW_APPROVAL: 'מסך אישור זכאות',
  FUNDING_FLOW_GET_CODE: 'מסך קבלת הקוד',
  FUNDING_FLOW_USER_DETAILS: 'מסך טופס פרטים אישיים',
  FUNDING_FLOW_THE_CODE: 'הקוד הינו',
  SAFETY_SYSTEM_EXIST: 'קיים',
  SAFETY_SYSTEM_NOT_EXIST: 'לא קיים',
  SEARCH_FLOW_BY: 'חיפוש לפי',
  VIDEO: 'סרטון',
  BRANCH_REGION: 'אזור',
  SELECT_AGE_DRIVER_AGE: 'גיל הנהג',
  MINIMUM_PRICE: 'מחיר מינימום',
  MAXIMUM_PRICE: 'מחיר מקסימום',
  SHEKEL: 'ש״ח',
  GALLERY: 'גלרייה',
  SERVICES_CAROUSEL: 'קרוסלת שירותים',
  CAROUSEL: 'קרוסלה',
  SEARCH_BY_CATEGORY: 'לחיפוש לפי קטגורי',
  SEARCH_BY_BRAND: 'לחיפוש לפי המותג',
  SEARCH_BY_BUDGET_LEASING: 'לחיפוש מכונית ליסינג פרטי',
  DISABLED_FORM_BUTTON: 'שדות לא תקינים אנא חזור ותקן ',
  NEXT_CARD: 'כרטיסייה הבאה ב',
  PREV_CARD: 'כרטיסייה קודמת ב',
  MAX_500_LETTERS: 'כתבו עד 500 תווים',
  INTERIOR_DESIGN: 'גלריית תמונות עיצוב פנים',
  CLEAR_FILTER: 'נקה סינון',
};

export const CONTACT_US_TEXT = {
  title: 'רוצה לדעת עוד?',
  whatsappLink:
    'https://wa.me/972502807648?text=%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A9%D7%9E%D7%95%D7%A2%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D%20%D7%9E%D7%A0%D7%A6%D7%99%D7%92%20freesbe',
  whatsappLinkNewCar:
    'https://wa.me/972502807648?text=%D7%9C%D7%A9%D7%99%D7%97%D7%94%20%D7%A2%D7%9D%20%D7%A0%D7%A6%D7%99%D7%92%20%20freesbe%20%D7%91%D7%A0%D7%95%D7%A9%D7%90%20%D7%A8%D7%9B%D7%91%20%D7%97%D7%93%D7%A9',
  whatsappLinkUsedCar:
    'https://wa.me/972502807648?text=%D7%9C%D7%A9%D7%99%D7%97%D7%94%20%D7%A2%D7%9D%20%D7%A0%D7%A6%D7%99%D7%92%20%20freesbe%20%D7%91%D7%A0%D7%95%D7%A9%D7%90%20%D7%A8%D7%9B%D7%91%20%D7%99%D7%93%20%D7%A9%D7%A0%D7%99%D7%99%D7%94',
  whatsappLinkPrivateLeasing:
    'https://wa.me/972502807648?text=%D7%9C%D7%A9%D7%99%D7%97%D7%94%20%D7%A2%D7%9D%20%D7%A0%D7%A6%D7%99%D7%92%20%20freesbe%20%D7%91%D7%A0%D7%95%D7%A9%D7%90%20%D7%9C%D7%99%D7%A1%D7%99%D7%A0%D7%92%20%D7%A4%D7%A8%D7%98%D7%99',
  subtitle: 'פשוט ליצור איתנו קשר',
  phone: '077-8040834',
  newCarPhone: '077-8048850',
  XpengPhone: '077-9966952',
  NissanPhone: '077-8041797',
  RenaultPhone: '077-8046978',
  DaciaPhone: '077-8045816',
  CherryPhone: '077-8042853',
  EveasyPhone: '077-8036035',
  InfinityPhone: '077-8053516',
  usedCarPhone: '077-8037360',
  privateleasingPhone: '077-2308096',
};
